import React, { Component } from "react";
import { Button, Col, Divider, Form, Modal, Row, Select, Spin, Statistic, Table, Tag } from "antd";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import Column from "antd/lib/table/Column";

import moment from "moment";

import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";

import { afdFileService, equipmentsService } from "../../redux/services";
import { DatePickerWithMask, UIUpload } from "../../components";
import { API_ERRO_TYPE_CANCEL } from "../../config/general";
import { formatTzDate } from "../../utils/formatTzDate";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			data: [],
			fetchInterval: null,
			equipmentsIsLoading: false,
			equipments: [],
			equipamento_id: null,
		};
	}

	componentDidMount() {
		this.fetchEquipments();
		this.fetchGetAll();
	}

	componentWillUnmount() {
		this.clearIntervalFetch();
	}

	fetchGetAll = (isInterval = false) => {
		const { fetchInterval } = this.state;
	
		this.setState({
			isLoading: !isInterval,
		});
	
		afdFileService
			.getAll({
				orderBy: "created_at:desc",
			})
			.then((response) => {
				const { data } = response.data || {};
				this.setState({
					isLoading: isInterval,
					data: data || [],
				});
	
				const hasFetch = data.some((item) => {
					// Verifica o status geral do arquivo
					if (["importando", "aguardando_importacao"].includes(item.status)) {
						return true;
					}
	
					if (
						item.arquivos &&
						item.arquivos.some((arquivo) =>
							arquivo.afd_records?.some(
								(record) => !['failed', 'success', 'successed'].includes(record.status)
							)
						)
					) {
						return true;
					}
	
					return false;
				});
	
				if (hasFetch) {
					if (!fetchInterval) {
						this.initTimeoutFetch();
					}
				} else {
					this.clearIntervalFetch();
				}
			})
			.catch((data) => {
				this.setState({
					isLoading: isInterval,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};	

	fetchEquipments = () => {
    this.setState({
      equipmentsIsLoading: true,
    })

    equipmentsService
      .getAll({
        is_active: 1,
				limit: 100,
      })
      .then((response) => {
        this.setState({
          equipmentsIsLoading: false,
          equipments: response?.data?.data || [],
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          equipmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

	resetFields = () => {
		this.setState({});
	};

	clearIntervalFetch = async () => {
		if (this.state.fetchInterval) {
			clearInterval(this.state.fetchInterval);
			this.setState({ fetchInterval: null });
		}
	}

	initTimeoutFetch = () => {
		const interval = setInterval(() => {
			this.fetchGetAll();
		}, 10000);
		this.setState({ fetchInterval: interval });
	}

	onFinish = (values) => {
		const file = this.upload.getFiles();

		if (file.hasError) {
			Modal.error({
				title: "Ocorreu um erro!",
				content: file.error,
			});

			return false;
		}

		if (!file.files.length) {
			Modal.error({
				title: "Aviso!",
				content: "Preencha o campo Arquivo (txt)",
			});

			return false;
		}

		this.setState({
			isSending: true,
		});

		const data = { 
			...values,
			tipo: 'manual',
		};
		data.data_inicial = data.data_inicial ? moment(data.data_inicial).format('YYYY-MM-DD') : null;

		// File
		if (file.files.length) {
			if (!file.files[0].uuid) {
				data.afd = file.files[0];
			}
		} else {
			data.afd = null;
		}

		afdFileService.upload(data)
			.then((response) => {
				this.setState({
					isSending: false,
				}, () => {
					Modal.success({
						content: "Ação realizada com sucesso!",
						okText: "Ok",
					});

					if (this.upload) this.upload.reset();

					this.fetchGetAll();
				});
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onEquipamentoChange = (equipamento_id) => {
		this.setState({ equipamento_id });
	};

	render() {
		const { 
		isLoading, 
		isSending, 
		data, 
		equipments, 
		equipamento_id, 
		equipmentsIsLoading,
		fetchInterval
		} = this.state;

		return (
			<QueueAnim className="site-content-inner page-import-afd-file alternative-wrapper">
				<div className="page-content fixed-header" key="1">
					<h1 className="page-title">Importar Arquivo AFD</h1>

					<Spin spinning={isLoading}>
						<Table
							dataSource={data}
							bordered
							loading={{
								spinning: isLoading,
								indicator: <i className="fad fa-spinner-third fa-spin fa-2x" />,
							}}
							rowKey="uuid"
							locale={{ emptyText: "Nenhum registro encontrado." }}
							pagination={false}
						>
							<ColumnGroup title="Última importação">
								<Column title="Data da Importação" dataIndex="data_importacao" key="data_importacao" render={(_, { data_importacao }) => (
									<div>{formatTzDate(data_importacao, '-03:00') ?? '-'}</div>
								)} />
								<Column
									title="Descrição"
									dataIndex="descricao"
									key="descricao"
									render={(_, { equipamento }) => (
										<div>{equipamento?.descricao ?? "-"}</div>
									)}
								/>
								<Column
									title="Número de série"
									dataIndex="numero_serial_rep"
									key="numero_serial_rep"
									render={(_, { numero_serial_rep }) => (
										<div>{numero_serial_rep ?? "-"}</div>
									)}
								/>
								<Column
									title="Nome do arquivo"
									dataIndex="arquivo_nome"
									key="arquivo_nome"
									render={(_, { arquivo_nome }) => (
										<div>{arquivo_nome ?? "-"}</div>
									)}
								/>
								<Column title="A partir de" dataIndex="data_inicial" key="data_inicial" render={(_, { data_inicial }) => (
									<div>{data_inicial ? moment(data_inicial).format("DD/MM/YYYY") : "-"}</div>
								)} />
								<Column
									title="Total de Registros"
									dataIndex="total_registro"
									key="total_registro"
									render={(_, { status, arquivos }) => (
										status !== 'aguardando_importacao' ? <Statistic 
											valueStyle={{ fontSize: 15 }} 
											// eslint-disable-next-line no-mixed-operators
											value={` ${arquivos.reduce((acc, item) => acc + item?.afd_records?.reduce((acc2, item2) => acc2 + (['failed', 'success', 'successed'].includes(item2?.status) && item2?.subtotal || 0), 0), 0)}`} 
											suffix={`/ ${arquivos.reduce((acc, item) => acc + item?.total_linhas, 0)}`} 
										/> : '--'
									)}
								/>
								<Column 
									title="Status" 
									dataIndex="status" 
									key="status" 
									render={(_, { status, status_nome, status_cor, arquivos }) => (
										<>
											{status !== 'importado' || arquivos.every(item => item.status_nome === 'Importado') ? <Tag color={status_cor}>{status_nome}</Tag> : <Tag color="#009dcc">Importando</Tag> }
										</>
									)} 
								/>
							</ColumnGroup>
						</Table>
					</Spin>

					<Divider />

					<Form
						ref={(el) => (this.form = el)}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
						disabled={fetchInterval}
						initialValues={{}}
					>
						<Row gutter={16}>
							<Col span={16}>
								<Row gutter={16}>
									<Col span={18}>
										<Row gutter={16}>
											<Col xs={24}>
												<label className="form-label required">Equipamento:</label>
												<Form.Item
													name="equipamento_id"
												>
													<Select
														optionFilterProp="children"
														filterOption={(input, option) => {
															const data = (option.children?.join(' ') || '').toLowerCase();
															return data.indexOf(input.toLowerCase()) > 0;
														}}
														allowClear
														placeholder="Selecione o equipamento"
														notFoundContent={
															equipmentsIsLoading ? (
																<Spin
																	indicator={
																		<i className="fad fa-spinner-third fa-spin" />
																	}
																/>
															) : null
														}
														onChange={this.onEquipamentoChange}
														showSearch
														style={{ width: '100%' }}
													>
														{equipments.map((item, index) => (
															<Select.Option key={index} value={item.id}>
																{item.descricao} ({item.numero_serial_rep} - {(item.configuracao?.tipo_documento || 'S/I').toUpperCase()})
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col xs={12} style={{ marginTop: 10 }}>
												<DatePickerWithMask
													label="A partir da data"
													name="data_inicial"
													required={true}
													disabled={!equipamento_id}
												/>
											</Col>	
										</Row>	
									</Col>

									<Col offset={2} span={4}>
										<UIUpload
											ref={(el) => (this.upload = el)}
											label="Arquivo (txt):"
											labelError="Arquivo (txt):"
											acceptedFiles={["txt"]}
											disabled={!equipamento_id}
										/>
										<Button
											type="primary"
											htmlType="submit"
											shape="round"
											className="btn-save"
											icon={<i className="far fa-check" />}
											loading={isSending}
											disabled={!equipamento_id || isSending}
										>
											{isSending ? "Importando" : "Importar"}
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</div>
			</QueueAnim>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Index);
