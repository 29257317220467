import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Button, Col, Form, Modal, Row } from 'antd'
import { DatePickerWithMask } from '../../components'
import moment from 'moment'

class ChangeIntegrationDate extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentItem: null,
    }
  }

  onOpen = (item) => {
    const formattedItem = {
      ...item,
      data_inicio: item ? moment(item.data_inicio) : null,
      data_fim: item ? moment(item.data_fim) : null,
    };

    this.setState({ currentItem: formattedItem });
  };

  onClose = () => {
    this.props.onClose();
  }

  integrationOnConfirm = (values) => {
    const { currentItem } = this.state;
    const data_inicio = moment(values.data_inicio).format('YYYY-MM-DD');
    const data_fim = moment(values.data_fim).format('YYYY-MM-DD');

    const eventColumnData = currentItem.eventos.map((event) => ({
      [`column`]: event.column,
      [`eventCode`]: event.eventCode
    }));

    const data = {
      ...currentItem,
      data_inicio,
      data_fim,
      eventColumnData
    }

    delete data.eventos;

    this.props.onComplete(data);
  };

  render() {
    const { visible } = this.props;
    const { currentItem } = this.state;

    return (
      <Modal
        visible={visible}
        title='Deseja alterar o período de integração?'
        centered={true}
        destroyOnClose={true}
        maskClosable={true}
        width={700}
        okText="Aplicar"
        onCancel={this.onClose}
        onOk={() => this.form.submit()}
        className="modal-filters"
        footer={[
          <Button key="back" type="link" onClick={this.onClose}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={() => this.form.submit()}>
            Aplicar
          </Button>,
        ]}
      >
        <Form
          ref={(el) => (this.form = el)}
          layout="vertical"
          scrollToFirstError
          initialValues={{
            data_inicio: currentItem ? currentItem.data_inicio : null,
            data_fim: currentItem ? currentItem.data_fim : null,
          }}
          onFinish={this.integrationOnConfirm}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
            <DatePickerWithMask
              onKeyUp={(e) => {
                if (e.keyCode === 13 || e.keyCode === 9) {
                  const dateValue = moment(e.target.value, 'YYYY-MM-DD', true);
                  if (dateValue.isValid()) {
                    this.form.setFieldValue('data_fim', dateValue);
                  }
                }
              }}
              label="Data inicial"
              name="data_inicio"
              defaultValue={currentItem ? moment(currentItem.data_inicio) : null}
              required={true}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório.",
                },
                {
                  validator: async (_, data_inicio) => {
                    const data_fim = moment(this.form.getFieldValue("data_fim"));
                    const startDate = moment(data_inicio);
                    if (data_fim.isValid() && startDate.isAfter(data_fim)) {
                      return Promise.reject("A data inicial deve ser menor ou igual a data final.");
                    }
                  },
                },
              ]}
            />
            </Col>
            <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Data final"
              name="data_fim"
              onKeyUp={(e) => {
                if (e.keyCode === 13 || e.keyCode === 9) {
                  const dateValue = moment(e.target.value, 'YYYY-MM-DD', true);
                  if (dateValue.isValid()) {
                    this.form.setFieldValue('data_inicio', dateValue);
                  }
                }
              }}
              defaultValue={currentItem ? moment(currentItem.data_fim) : null}
              required={true}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório.",
                },
                {
                  validator: async (_, data_fim) => {
                    const data_inicio = moment(this.form.getFieldValue("data_inicio"));
                    const endDate = moment(data_fim);
                    if (data_inicio.isValid() && endDate.isBefore(data_inicio)) {
                      return Promise.reject("A data final deve ser maior ou igual a data inicial.");
                    }
                  },
                },
              ]}
            />
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

export default ChangeIntegrationDate
