import React, { Component, Fragment } from "react";
import { Modal, Spin, Empty, Button } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { reportService, columnsService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			data: [],
			columnsData: {},
			columnsList: [],
			dataInicial : "",
			dataFinal: "",
			empresa_nome: "",
			empresa_id: 0,
			totais: {},
			max_horas_extras_faixa: [],
			agrupar: false,
		};
	}

	componentDidMount() {
		document.body.classList.add("page-print");

		const params = new URLSearchParams(this.props.location.search);

		this.setState({
			agrupar: params.get('agrupar'),
		})

		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove("page-print");
	}

	handleGetCompany = (params) => {
		if (!params.has("empresas")) {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Empresa não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			});

			return;
		}

		this.setState({
			empresa_nome: params.get("empresa_nome"),
		});

		const idEmpresas = params.get("empresas").split(",");

		columnsService
		.show({ empresa_id: idEmpresas })
		.then((response) => {
			this.setState(
				{
					columnsData: response.data.data[0],
				},
			)
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			})
		});

		return idEmpresas;
	}

	handleGetDepartament = (params) => {
		if (!params.has("departamentos") || params.get("departamentos") === "todos") return;

		return params.get("departamentos").split(",");
	}

	handleGetEmployee = (params) => {
		if (!params.has("funcionarios") || params.get("funcionarios") === "todos") return;

		return params.get("funcionarios").split(",");
	}

	handleGetInitialDate = (params) => {
		if (!params.has("data_inicial")) {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Data inicial não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			});

			return;
		}
		
		this.setState({
			dataInicial: params.get("data_inicial"),
		});

		return params.get("data_inicial");
	}

	handleGetFinalDate = (params) => {
		if (!params.has("data_final")) {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Data final não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			});
		}

		this.setState({
			dataFinal: params.get("data_final"),
		});

		return params.get("data_final");
	}

	handleGetGroup = (params) => {
		if (!params?.has("agrupar")) return;

		return params.get("agrupar");
	}

	getMaxHorasExtrasFaixa = (items = []) => {
		const { agrupar } = this.state;

		let allHorasExtras = [];

		if (agrupar) {
			items.forEach((item) => {
					const funcTotais = item?.func_totais || [];
					funcTotais.forEach((funcTotal) => {
						const horas_extras_faixa = funcTotal.horas_extras_faixa || [];
						if (horas_extras_faixa.length > 0) {
							allHorasExtras = [...allHorasExtras, ...horas_extras_faixa];
						}
					});
			});
		} else {
			items.forEach((item) => {
				const horas_extras_faixa = item?.horas_extras_faixa || [];
				if (horas_extras_faixa?.length > 0) {
					allHorasExtras = [...allHorasExtras, ...horas_extras_faixa];
				}
			});
		}

		return allHorasExtras;
	};

	handleMakeRequest = (payload) => {
		reportService.totals(payload)
			.then((response) => {
				const params = new URLSearchParams(this.props.location.search);
				const agrupar = params.get('agrupar');

				const allHorasExtras = this.getMaxHorasExtrasFaixa(
					!agrupar ? response.data.lista : response.data.agrupado
				);

				const data = !agrupar
					? [
						{
							Empresa: null,
							Totais: response.data.totais,
							departamento: null,
							func_totais: response.data.lista,
							horas_extras_faixa: allHorasExtras,
						}
					] : response.data.agrupado;

				const { horas_extras_faixa, ...Totais } = response.data.totais || {};

				this.setState({
					isLoading: false,
					data,
					totais: {
						horas_extras_faixa,
						Totais,
					},
					max_horas_extras_faixa: allHorasExtras,
				}, () => {
					setTimeout(() => {
						// Print
						document.title = 'Totais';
						if (this.state.data.length > 0) {
							window.print();
							// window.close();
						}
					}, 1000);
				});
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = new URLSearchParams(this.props.location.search);

		const payload = {
			empresas: this.handleGetCompany(params),
			agrupar: this.handleGetGroup(params),
			data_inicial: this.handleGetInitialDate(params),
			data_final: this.handleGetFinalDate(params),
			funcionarios: this.handleGetEmployee(params),
			departamentos: this.handleGetDepartament(params),
		};
	
		Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key]);

		this.handleMakeRequest(payload);
	}

	handleGetExtraLabel = () => {
		const { columnsData, max_horas_extras_faixa } = this.state;
		const {
			hora_extra_diurna,
			hora_extra_noturna,
			hora_extra_intervalo,
		} = columnsData || {};
	
		const processedIds = new Set();
		const filteredFaixas = max_horas_extras_faixa?.filter(faixa => {
			const shouldProcess =
				(hora_extra_diurna && faixa.periodo === "diurno") ||
				(hora_extra_intervalo && faixa.periodo === "intervalo") ||
				(hora_extra_noturna && faixa.periodo === "noturno");
	
			if (shouldProcess && !processedIds.has(faixa.id)) {
				processedIds.add(faixa.id);
				return true;
			}
			return false;
		});
	
		if (!filteredFaixas || filteredFaixas.length === 0) {
			return null;
		}
	
		const getPeriodoLabel = (periodo) => {
			switch (periodo) {
				case "noturno":
					return "N";
				case "intervalo":
					return "I";
				case "diurno":
					return "D";
				default:
					return "";
			}
		};
		
		return filteredFaixas.map(faixa => (
			<div key={faixa.id} style={{ textAlign: 'center', marginRight: '15px', lineHeight: '1.08' }}>
				<div>Extra</div>
				<div>{parseFloat(faixa.percentual)}% {getPeriodoLabel(faixa.periodo)}</div>
			</div>
		));
	};		

	handleGetExtraValues = (item) => {
		const { columnsData, max_horas_extras_faixa } = this.state;
		const {
			hora_extra_diurna,
			hora_extra_noturna,
			hora_extra_intervalo,
		} = columnsData || {};
	
		const processedIds = new Set();
		const filteredFaixas = max_horas_extras_faixa?.filter(faixa => {
			const shouldProcess =
				(hora_extra_diurna && faixa.periodo === "diurno") ||
				(hora_extra_intervalo && faixa.periodo === "intervalo") ||
				(hora_extra_noturna && faixa.periodo === "noturno");
	
			if (shouldProcess && !processedIds.has(faixa.id)) {
				processedIds.add(faixa.id);
				return true;
			}
			return false;
		});
	
		return filteredFaixas?.map(faixa => ({ value: item?.Totais?.horas_extras && item?.Totais?.horas_extras[faixa.id] }));
	}; 

	handleValue = (value = '00:00', useDefaultForZero = false) => {
		return value === '00:00' && useDefaultForZero ? '00:00' : value === '00:00' ? ' ' : value;
	};

	handleTotalValue = (value = '00:00') => {
		return value === '00:00' ? '00:00' : value
	}

	formatLabel = (label) => {
		const parts = label.split(' ');
		return (
			<div style={{ textAlign: 'center', lineHeight: '1.05' }}>
				<div>{parts[0]}</div>
				<div>{parts.slice(1).join(' ')}</div>
			</div>
		);
	}

	getCommonHeaders = () => {
    const { columnsData } = this.state;
    const {
      apuracao_normal,
      apuracao_faltas,
      apuracao_carga,
			apuracao_atraso,
      apuracao_falta_dia,
      apuracao_adiconal_noturno,
      apuracao_abono,
      apuracao_ajuste,
      apuracao_dsr,
			apuracao_dsr_debito,
      apuracao_extra,
			banco_hora,
    } = columnsData || {};

    let columns = [];

    if (apuracao_normal) columns.push('Normal');
    if (apuracao_faltas) columns.push('Faltas');
    if (apuracao_carga) columns.push('Carga');
		if (apuracao_atraso) columns.push('Atraso');
		if (apuracao_falta_dia) columns.push(this.formatLabel('Falta Dia'));
    if (apuracao_adiconal_noturno) columns.push(this.formatLabel('Adic. Noturno'));
    if (apuracao_abono) columns.push('Abono');
    if (apuracao_ajuste) columns.push('Ajuste');
    if (apuracao_dsr) columns.push('Dsr');
    if (apuracao_dsr_debito) columns.push(this.formatLabel('Dsr Déb.'));

    if (apuracao_extra) columns.push('Extra');

		const extras = this.handleGetExtraLabel();
		if (extras && extras.length >= 0) {
			columns.push(...extras);
		}

    if (banco_hora) columns.push('BCréd', 'BDéb', 'BTotal', 'BSaldo', 'BAjus');

    return columns;
}

	getHeaders = () => {
		return [
			'Folha',
			'Nome',
			...this.getCommonHeaders()
		];
	}

	getTotalHeaders = (items) => {
		return [
			'Totais',
			'',
			...this.getCommonHeaders()
		];
	}

	getTotaisColumnsValues = (group = {}, titulo) => {
    const { columnsData } = this.state;
    const {
			apuracao_normal,
			apuracao_faltas,
			apuracao_carga,
			apuracao_adiconal_noturno,
			apuracao_abono,
			apuracao_atraso,
			apuracao_falta_dia,
			apuracao_ajuste,
			apuracao_dsr,
			apuracao_dsr_debito,
			apuracao_extra,
			banco_hora,
    } = columnsData || {};

    let totaisValues = [titulo || 'Sub-Totais', ''];

    if (apuracao_normal) totaisValues.push(this.handleTotalValue(group?.Totais?.total_horas_normais));
    if (apuracao_faltas) totaisValues.push(this.handleTotalValue(group?.Totais?.total_horas_faltas));
    if (apuracao_carga) totaisValues.push(this.handleTotalValue(group?.Totais?.total_horas_carga));
    if (apuracao_atraso) totaisValues.push(this.handleTotalValue(group?.Totais?.total_horas_atraso));
    if (apuracao_falta_dia) totaisValues.push(this.handleTotalValue(group?.Totais?.total_falta_dia));
    if (apuracao_adiconal_noturno) totaisValues.push(this.handleTotalValue(group?.Totais?.total_adiconal_noturo));
    if (apuracao_abono) totaisValues.push(this.handleTotalValue(group?.Totais?.total_abono));
    if (apuracao_ajuste) totaisValues.push(this.handleTotalValue(group?.Totais?.total_ajustado));
    if (apuracao_dsr) totaisValues.push(this.handleTotalValue(group?.Totais?.total_dsr));
    if (apuracao_dsr_debito) totaisValues.push(this.handleTotalValue(group?.Totais?.total_dsr_debito));
    if (apuracao_extra) totaisValues.push(this.handleTotalValue(group?.Totais?.total_horas_extras));

		const extras = this.handleGetExtraValues(group)?.map(({ value }) => this.handleValue(value));
    if (extras && extras.length >= 0) totaisValues.push(...extras);

		if (banco_hora) {
			totaisValues.push(
				this.handleTotalValue(group?.Totais?.bh_horas_extras),
				this.handleTotalValue(group?.Totais?.bh_horas_falta),
				this.handleTotalValue(group?.Totais?.bh_horas_total),
				this.handleTotalValue(group?.Totais?.bh_horas_saldo),
				this.handleTotalValue(group?.Totais?.bh_horas_ajuste)
			);
		}

    return totaisValues;
	}

	getColumnsValues = (group = {}) => {
    const { columnsData } = this.state;
    const {
			apuracao_normal,
			apuracao_faltas,
			apuracao_carga,
			apuracao_adiconal_noturno,
			apuracao_abono,
			apuracao_atraso,
			apuracao_falta_dia,
			apuracao_ajuste,
			apuracao_dsr,
			apuracao_dsr_debito,
			apuracao_extra,
			banco_hora,
    } = columnsData || {};

    return (group?.func_totais || []).map((item) => {
        let values = [
            item?.Funcionario?.numero_folha ?? '-',
            item?.Funcionario?.nome ?? '-',
        ];

        if (apuracao_normal) values.push(this.handleValue(item?.Totais?.total_horas_normais));
        if (apuracao_faltas) values.push(this.handleValue(item?.Totais?.total_horas_faltas));
        if (apuracao_carga) values.push(this.handleValue(item?.Totais?.total_horas_carga));
        if (apuracao_atraso) values.push(this.handleValue(item?.Totais?.total_horas_atraso));
        if (apuracao_falta_dia) values.push(this.handleValue(item?.Totais?.total_falta_dia));
        if (apuracao_adiconal_noturno) values.push(this.handleValue(item?.Totais?.total_adiconal_noturo));
        if (apuracao_abono) values.push(this.handleValue(item?.Totais?.total_abono));
        if (apuracao_ajuste) values.push(this.handleValue(item?.Totais?.total_ajustado));
        if (apuracao_dsr) values.push(this.handleValue(item?.Totais?.total_dsr));
				if (apuracao_dsr_debito) values.push(this.handleValue(item?.Totais?.total_dsr_debito));
        if (apuracao_extra) values.push(this.handleValue(item?.Totais?.total_horas_extras));

				const extras = this.handleGetExtraValues(item)?.map(({ value }) => this.handleValue(value));
        if (extras && extras.length >=0) values.push(...extras);

				if (banco_hora) {
					values.push(
						this.handleValue(item?.Totais?.bh_horas_extras),
						this.handleValue(item?.Totais?.bh_horas_falta),
						this.handleValue(item?.Totais?.bh_horas_total),
						this.handleValue(item?.Totais?.bh_horas_saldo, true),
						this.handleValue(item?.Totais?.bh_horas_ajuste),
					);
				}

        return values;
    });
	}

	getTableStyle = (index, columnCount) => {
    let style = { fontSize: '9px', textAlign: 'center', marginLeft: '5px', marginRight: '5px', width: '50px' };

    if (index === 0) {
			style = { ...style, width: '65px', textAlign: 'left' };
    } else if (index === 1) {
			style = { ...style, minWidth: 'max-content', whiteSpace: 'nowrap', textAlign: 'left' };
    } else if (index === columnCount - 1) {
			style = { ...style, width: 'auto', textAlign: 'left' };
    }

    return style;
  };  

	getStyleHeadersTable = (index, listColumns) => {
    const baseStyle = { fontSize: '9px', marginLeft: '5px', marginRight: '5px', width: '50px' };
    
    return index === 0 ? 
			{ ...baseStyle, textAlign: 'left' } : 
			index === 1 ? 
			{ ...baseStyle,  minWidth: 'max-content', textAlign: 'left' } : 
			index === listColumns.length - 1 ? 
			{ ...baseStyle, width: 'auto', textAlign: 'left' } : 
			{ ...baseStyle, textAlign: 'center' };
	};

	isLastHeaderAnObject() {
    const headers = this.getHeaders();
    const lastHeader = headers[headers.length - 1];
    return React.isValidElement(lastHeader);
  }

	render() {
		const { isLoading, data, totais } = this.state;
		const { user } = this.props;
		const { name: userName } = user || {};

		const list = this.getHeaders();
		const listTotais = this.getTotaisColumnsValues(totais, 'Total');
		const isObjectLastColumn = this.isLastHeaderAnObject();

		const style = 
			`@page { size: A4 landscape; margin: 0 }`;

		return (
			<Fragment>
				{isLoading ? (
					<div
						style={{
							position : "fixed",
							top      : "150px",
							left     : "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spin
							spinning={isLoading}
							indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
						/>
					</div>
				) : (
					data?.length > 0 ? (
						<table className="table-impressao">
							<thead>
								<tr>
									<td className="time-card">
										<header>
											<div className="logo-container">
												<div>
													<img
														src="/images/logos/logo-complete.svg"
														alt="ByPonto"
														width="200"
													/>
												</div>
												<div className="logo-title">
													<div>
														<h1>Totais</h1>
													</div>
													<div>
														<div>Período: {moment(this.state.dataInicial).format('DD/MM/YYYY')} até {moment(this.state.dataFinal).format('DD/MM/YYYY')}</div>														
													</div>
													<div className="user-info">
														<h4>{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})</h4>
													</div>
												</div>
											</div>
										</header>
									</td>
								</tr>
							</thead>
							<tbody>
								{data.map((group, i) => {
									const listData = this.getColumnsValues(group);
									const listSubTotais = this.getTotaisColumnsValues(group);
									return (
										<div key={i} className="table-container mt-2rem m-1rem">
											{this.state.agrupar && (
												<div className="group-name">
													<b>Departamento</b>: {group?.departamento?.nome}
												</div>
											)}
											<table className="responsive-table" style={{ fontSize: '10px' }}>
												<thead>
													<tr>
													{
														list?.map((col, index) => {
															const isLastColumn = index === list.length - 1;
															const className = isLastColumn ? (isObjectLastColumn ? 'last-column-totalsReport-ml0' : 'last-column-totalsReport') : '';

															return (
																<th key={index} style={this.getStyleHeadersTable(index, list)}>
																	<div className={className}>
																		{col}
																	</div>
																</th>
															);
														})
													}
													</tr>
												</thead>
												<tbody>
													{listData?.map((row, index) => (
														<tr key={index}>
															{row?.map((col, colIndex) => (
																<td 
																	key={colIndex} 
																	style={this.getTableStyle(colIndex, list.length)}
																>
																	<div className={colIndex === list.length - 1 ? 'last-column-content' : ''}>
																		{col}
																	</div>
																</td>
															))}
														</tr>
													))}
													<tr>
														{listSubTotais?.map((col, index) => (
															<td 
																key={index} 
																style={col === 'Sub-Totais' || index === listSubTotais.length - 1 ? { textAlign: 'left', marginLeft: '5px' } : { textAlign: 'center' }}
															>
																<div className={index === listSubTotais.length - 1 ? 'last-column-content' : ''}>
																	{col}	
																</div>
															</td>
														))}
													</tr>
													{i === data.length - 1 && (
														<tr>
															{listTotais?.map((col, index) => (
																<td 
																	key={index} className="negrito-geral" 
																	style={col === 'Total' || index === listTotais.length - 1 ? { textAlign: 'left', marginLeft: '5px' } : { textAlign: 'center' }}
																>
																	<div className={index === listTotais.length - 1 ? 'last-column-content' : ''}>
																		{col}	
																	</div>
																</td>
															))}
														</tr>
													)}
												</tbody>
											</table>
										</div>
									)
								})}
							</tbody>
							<tfoot>
								<tr>
									<td>
										<div className="footer-space">&nbsp;</div>
									</td>
								</tr>
							</tfoot>
						</table>
					) : (
						<Empty description="Nenhum registro encontrado com esses parâmetros." style={{padding: "20px", position: "absolute", top: "20px", left: "50%", transform: "translateX(-50%)"}}>
							<Button type="primary" onClick={() => window.close()}>Voltar</Button>
						</Empty>
					)
				)}
				<style dangerouslySetInnerHTML={{__html: style}} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
