import React, { Component, Fragment } from "react";
import { Col, Modal, Spin, Row, Empty, Button } from "antd";
import moment from "moment";
import qs from 'querystring'
import { connect } from "react-redux";
import { reportService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			data_inicio: "",
			data_fim: "",
			data: [],
			media: [],
			tableData: [],

			columnsList: [],

			dataDia: "",
			empresa: [],
			groupBy: 0,
			totals: [],
		};
	}

	componentDidMount() {
		document.body.classList.add("page-print", "page-print-espelho-ponto");
		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove("page-print", "page-print-espelho-ponto");
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = qs.parse(this.props.location.search.replace('?', ''))

		const data = {};

		if (params?.empresas && params?.empresas !== 'todos') {
			this.setState({
				empresa_id: params?.empresas
			})

			data.empresas = params?.empresas;

			data.empresas = Array.isArray(params?.empresas)
				? params?.empresas
				: [params?.empresas];
		}

		if (params?.departamentos && params?.departamentos !== 'todos') {
			data.departamentos = Array.isArray(params?.departamentos)
			? params?.departamentos
			: [params?.departamentos];
		}

		if (params?.funcionarios && params?.funcionarios !== 'todos') {
			data.funcionarios = Array.isArray(params?.funcionarios)
			? params?.funcionarios
			: [params?.funcionarios];
	}

		if (params?.jornadas && params?.jornadas !== 'todos') {
			data.jornadas = Array.isArray(params?.jornadas)
			? params?.jornadas
			: [params?.jornadas];
		}

		if (params?.justificativas && params?.justificativas !== 'todos') {
			data.justificativas = Array.isArray(params?.justificativas)
			? params?.justificativas
			: [params?.justificativas];
		}
		
		if (params?.data_inicio) {
			data.data_inicio = params?.data_inicio;
      this.setState({
				data_inicio: params?.data_inicio,
      })
    } else {
			Modal.error({
				title: 'Ocorreu um erro!',
        content: 'Data inicial não encontrada!',
        maskClosable: false,
        keyboard: false,
        closable: false,
        onOk: () => {
					window.close();
        },
      })
      return false
    }
		
    if (params?.data_fim) {
			data.data_fim = params?.data_fim;
      this.setState({
				data_fim: params?.data_fim,
      })
    } else {
      Modal.error({
        title: 'Ocorreu um erro!',
        content: 'Data final não encontrada!',
        maskClosable: false,
        keyboard: false,
        closable: false,
        onOk: () => {
					window.close();
        },
      })
      return false
    }
		
		if (params?.includeMissedHoursAbsence) {
			data.includeMissedHoursAbsence = params?.includeMissedHoursAbsence;
		}

		if (params?.includeInactiveEmployees) {
			data.includeInactiveEmployees = params?.includeInactiveEmployees;
		}

		if (params?.includePartialJustifications) {
			data.includePartialJustifications = params?.includePartialJustifications;
		}

		if (params?.groupBy !== undefined) {
			this.setState({
				groupBy: params?.groupBy
			})
			
			if (params?.groupBy === '1') data.por = 'departamento';
			if (params?.groupBy === '2') data.por = 'funcionario';
			if (params?.groupBy === '3') data.por = 'jornada';			
		}
		
		reportService
		.absenteeism(data)
			.then((response) => {
				const responseArray = Object.values(response.data);

				this.setState({
					isLoading: false,
					data: responseArray,
					tableData: response.data.lista[0],
					totals: response.data.totals,
					media: response.data?.media,
					empresa: response.data?.empresa,
				});

				document.title = 'Absenteísmo';

				setTimeout(() => {
					window.print();
				}, 1000); 
				// window.close();
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	getHeaders = (groupByName) => {
		const uppercase = groupByName?.toUpperCase();

		const list = [
				uppercase,
				'PREVISTAS',
				'TRABALHADAS',
				'AUSÊNCIAS',
				'% DAS AUSÊNCIAS',
			];

		return list;
	};  

	getDataStructure = () => {
		const { empresa } = this.state;
	
		const dataStructure  = [
			{
				contrato: [
					{
						empresa: empresa
					},
				],
			},
		];
		return dataStructure;
	};

	groupByName = (groupBy) => {
		if (groupBy === '1') return 'Departamento';
		if (groupBy === '2') return 'Funcionário';
		if (groupBy === '3') return 'Horário';
	};

	render() {

		const {
			isLoading,
			media,
			tableData,
			groupBy,
			totals,
		} = this.state;
	
		const { user } = this.props;
		const { name: userName } = user || {};
		const groupByName = this.groupByName(groupBy);
	
		const dataStructure = this.getDataStructure();	
		const pageBreakAfter = tableData?.length > 1;

		return (
      <Fragment>
        {isLoading ? (
          <div
            style={{
              position: 'fixed',
              top: '150px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Spin
              spinning={isLoading}
              indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
            />
          </div>
        ) : dataStructure.length > 0 ? (
          <Fragment>
            {dataStructure?.map((item, i) => {
							const taxVar = item?.contrato[0]?.empresa[0]?.cnpj ? 'CNPJ' : 'CPF';
							const taxValue = item?.contrato[0]?.empresa[0]?.cnpj || item?.contrato[0]?.empresa[0]?.cpf;

							const list = this.getHeaders(groupByName);
							const listData = tableData;

							const style = `@page { size: A4 landscape; margin: 0 }`
							const tableClass = `table-impressao cartao-ponto-${item} ml-20px ${pageBreakAfter ? 'page-break-after' : ''}`;

              return (
                <>
									<table key={i} className={tableClass}>
										<thead>
                      <tr>
                        <td className="time-card">
                          <header className="mr-20px">
                            <div className="logo-container">
                              <div style={{ marginTop: 10 }}>
                                <img
                                  src="/images/logos/logo-complete.svg"
                                  alt="ByPonto"
                                  width="180"
																/>
                              </div>
                              <div className="logo-title">
                                <div>
                                  <h1>Absenteísmo</h1>
                                </div>
                                <div style={{ gap: '5px' }}>
                                  <div>Período: {moment(this.state.data_inicio).format('DD/MM/YYYY')} até {moment(this.state.data_fim).format('DD/MM/YYYY')}</div>
																	<div>Agrupado por {groupByName}</div>
                                </div>
                                <div className="user-info" style={{ marginTop: '5px' }}>
                                  <h4>{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})</h4>
                                </div>
                              </div>
                            </div>
                            <div className="info-container">
                              <Row gutter={16}>
																<Col span={12}>
																	<Row className="row-section-50">
																		<Col span={24}>
																		<div className='row-section-flex'>
																			<div style={{ fontSize: '13px'}}><strong>EMPRESA:</strong></div>
																			<div style={{ fontSize: '12px'}}>{item?.contrato[0]?.empresa[0]?.nome}</div>
																		</div>
																		</Col>
																		<Col span={12}>
																			<div className='row-section-flex'>
																			<div><strong>{taxVar}:</strong></div>
																			<div>{taxValue}</div>
																			</div>
																		</Col>
																		<Col span={12}>
																			<div className='row-section-flex'>
																			<div><strong>INSCRIÇÃO:</strong></div>
																			<div>{item?.contrato[0]?.empresa[0]?.ie}</div>
																			</div>
																		</Col>
																	</Row>
																</Col>
															</Row>
                            </div>
                          </header>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <main>
                            <div className="table-container" style={{ marginTop: '5px' }}>
                              <table className="responsive-table">
                                <thead>
                                  <tr>
                                    {list.map((col, index) => (
																			<th 
																			key={index}
																			style={{ 
																				width: index === list.length - 1 ? 'auto' : '120px',
																			}}
																			>
																				{col}
																			</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
																	{listData?.map((item, index) => (
																		<tr key={index}>
																			<td>
																			{this.state.groupBy === '3' ? item?.descricao : item?.nome}
																			</td>
																			<td>{item?.horasPrevistas}</td>
																			<td>{item?.horasTrabalhadas}</td>
																			<td>{item?.ausencia}</td>
																			<td>{item?.percentual_ausencia}</td>
																		</tr>
																	))}
																	{totals?.map((item, index) => (
																		<tr key={index}>
																			<td className="negrito" style={{ fontSize: '13px' }}>TOTAL</td>
																			<td className="negrito" style={{ fontSize: '13px' }}>{item?.horasPrevistas}</td>
																			<td className="negrito" style={{ fontSize: '13px' }}>{item?.horasTrabalhadas}</td>
																			<td className="negrito" style={{ fontSize: '13px' }}>{item?.ausencia}</td>
																			<td className="negrito" style={{ fontSize: '13px' }}>{item?.percentual_ausencia}</td>
																		</tr>
																	))}
																		<tr>
																			<td className="negrito" style={{ fontSize: '13px' }}>MÉDIA</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td className="negrito" style={{ fontSize: '13px' }}>{media}</td>
																		</tr>
                                </tbody>
                              </table>
                            </div>
                          </main>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <div className="footer-space">&nbsp;</div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <style dangerouslySetInnerHTML={{ __html: style }} />
                </>
              )
            })}
          </Fragment>
        ) : (
          <Empty
            description="Nenhum registro encontrado com esses parâmetros."
            style={{
              padding: '20px',
              position: 'absolute',
              top: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Button type="primary" onClick={() => window.close()}>
              Voltar
            </Button>
          </Empty>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
