import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { Menu } from 'antd'

const SubMenu = Menu.SubMenu

class MainNavigation extends Component {
  static propTypes = {
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: () => null,
  }

  render() {
    const { location } = this.props
    let base = ''
    let defaultOpenKeys = []
    let selectedKeys = []
    let paths = location.pathname.split('/').filter(function (el) {
      return el
    })

    let pathsGroups = ''

    paths.forEach((path, index) => {
      if (path) {
        if (index === 0) {
          base = `/${path}`
        }

        pathsGroups += `/${path}`

        defaultOpenKeys.push(pathsGroups)
      }
    })

    selectedKeys.push(location.pathname)
    selectedKeys.push(base)

    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={selectedKeys}
        onClick={this.props.onClick}
      >
        <Menu.Item key="/" icon={<i className="fal fa-tachometer-fast" />}>
          <NavLink to="/">Início</NavLink>
        </Menu.Item>
        {(this.props.permissions.includes('alert-exams.list') ||
          this.props.permissions.includes('anamnese.list') ||
          this.props.permissions.includes('exams-categories.list') ||
          this.props.permissions.includes('roles.list') ||
          this.props.permissions.includes('log.list') ||
          this.props.permissions.includes('system-log.list') ||
          this.props.permissions.includes('customers.list') ||
          this.props.permissions.includes('tenant-user-functions.list') ||
          this.props.permissions.includes('tenant-user-types.list') ||
          this.props.permissions.includes('users.list')) && (
          <SubMenu
            key="/administrator"
            title="Config. de Acesso"
            icon={<i className="fal fa-lock" />}
          >
            {this.props.permissions.includes('users.list') && (
              <Menu.Item key="/administrator/users">
                <NavLink to="/administrator/users">Usuários</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('roles.list') && (
              <Menu.Item key="/administrator/roles-and-permissions">
                <NavLink to="/administrator/roles-and-permissions">
                  Papéis e Permissões
                </NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('log.list') && (
              <Menu.Item key="/administrator/logs">
                <NavLink to="/administrator/logs">Logs de Alteração</NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {(this.props.permissions.includes('empresas.list') ||
          this.props.permissions.includes('horas-extras.list') ||
          this.props.permissions.includes('dsr.list') ||
          this.props.permissions.includes('empresas.list')) && (
          <SubMenu
            key="/cadastrogeral"
            title="Config. Gerais"
            icon={<i className="fal fa-cog" />}
          >
            {this.props.permissions.includes('empresas.list') && (
              <Menu.Item key="/list/companies">
                <NavLink to="/list/companies">Empresas</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('equipamentos.list') && (
              <Menu.Item key="/list/equipments">
                <NavLink to="/list/equipments">Equipamentos</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('horas-extras.edit') && (
              <Menu.Item key="/cadastro/overtime">
                <NavLink to="/cadastro/overtime">Horas Extras</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('dsr.edit') && (
              <Menu.Item key="/cadastro/dsr">
                <NavLink to="/cadastro/dsr">DSR</NavLink>
              </Menu.Item>
            )}
            {/* TODO - Permissão */}
            <Menu.Item key="/cadastro/perimeters">
              <NavLink to="/cadastro/perimeters">Perímetros</NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {(this.props.permissions.includes('horarios.list') ||
          this.props.permissions.includes('funcionarios.list') ||
          this.props.permissions.includes('departamentos.list') ||
          this.props.permissions.includes('funcoes.list') ||
          this.props.permissions.includes('feriados.list') ||
          this.props.permissions.includes('justificativas.list') ||
          this.props.permissions.includes('banco-de-horas.list')) && (
          <SubMenu
            key="/cadastro"
            title="Cadastros"
            icon={<i className="fal fa-users" />}
          >
            {this.props.permissions.includes('horarios.edit') && (
              <Menu.Item key="/cadastro/time">
                <NavLink to="/cadastro/time">Horários</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('jornadas.edit') && (
              <Menu.Item key="/cadastro/journeys">
                <NavLink to="/cadastro/journeys">Jornadas</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('funcionarios.edit') && (
              <Menu.Item key="/cadastro/employees">
                <NavLink to="/cadastro/employees">Funcionários</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('departamentos.edit') && (
              <Menu.Item key="/cadastro/departments">
                <NavLink to="/cadastro/departments">Departamentos</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('funcoes.edit') && (
              <Menu.Item key="/cadastro/functions">
                <NavLink to="/cadastro/functions">Funções</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('feriados.edit') && (
              <Menu.Item key="/cadastro/holidays">
                <NavLink to="/cadastro/holidays">Feriados</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('justificativas.edit') && (
              <Menu.Item key="/cadastro/justifications">
                <NavLink to="/cadastro/justifications">Justificativas</NavLink>
              </Menu.Item>
            )}
            {this.props.permissions.includes('banco-de-horas.edit') && (
              <Menu.Item key="/cadastro/annual-leave">
                <NavLink to="/cadastro/annual-leave">Banco de Horas</NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {this.props.permissions.includes('justificativa-parcial.list') && (
          <SubMenu
            key="/gestao"
            title="Gestão"
            icon={<i className="fal fa-book-open" />}
          >
            {/* TODO - Permissão */}
            <Menu.Item key="/gestao/dailypoint">
              <NavLink to="/gestao/dailypoint">Ponto Diário</NavLink>
            </Menu.Item>
            <Menu.Item key="/gestao/timecard">
              <NavLink to="/gestao/timecard">Cartão Ponto</NavLink>
            </Menu.Item>
            {this.props.permissions.includes('justificativa-parcial.edit') && (
              <SubMenu
                key="/gestao/lancamentos-coletivos"
                title="Lançamentos Coletivos"
              >
                {this.props.permissions.includes(
                  'justificativa-parcial.edit'
                ) && (
                  <Menu.Item key="/gestao/lancamentos-coletivos/partial-justifications">
                    <NavLink to="/gestao/lancamentos-coletivos/partial-justifications">
                      Justificativa Parcial
                    </NavLink>
                  </Menu.Item>
                )}
                {this.props.permissions.includes('afastamentos.list') && (
                  <Menu.Item key="/gestao/lancamentos-coletivos/afastamentos">
                    <NavLink to="/gestao/lancamentos-coletivos/afastamentos">
                      Afastamentos
                    </NavLink>
                  </Menu.Item>
                )}
                {this.props.permissions.includes(
                  'lancamentos-coletivos.recalcular-horarios'
                ) && (
                  <Menu.Item key="/gestao/lancamentos-coletivos/recaulcular-horarios">
                    <NavLink to="/gestao/lancamentos-coletivos/recaulcular-horarios">
                      Recalcular Horários
                    </NavLink>
                  </Menu.Item>
                )}
                {this.props.permissions.includes(
                  'fechamento-periodo-calculo.list'
                ) && (
                  <Menu.Item key="/gestao/lancamentos-coletivos/fechamento-periodo-calculo">
                    <NavLink to="/gestao/lancamentos-coletivos/fechamento-periodo-calculo">
                      Fechamento do Período de Cálculo
                    </NavLink>
                  </Menu.Item>
                )}
                {this.props.permissions.includes(
                  'lancamentos-coletivos.excecao-de-jornada'
                ) && (
                  <Menu.Item key="/gestao/lancamentos-coletivos/excecao-de-jornada">
                    <NavLink to="/gestao/lancamentos-coletivos/excecao-de-jornada">
                      Exceção de Horário/HE
                    </NavLink>
                  </Menu.Item>
                )}
                {/* TODO - Permissão */}
                <Menu.Item key="/gestao/lancamentos-coletivos/manutencao-de-banco-de-horas">
                  <NavLink to="/gestao/lancamentos-coletivos/manutencao-de-banco-de-horas">
                    Manutenção do Banco de Horas
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )}
            {/* TODO - Permissão */}
            <Menu.Item key="/gestao/import-afd-file">
              <NavLink to="/gestao/import-afd-file">
                Importar Arquivo AFD
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/gestao/integrations">
              <NavLink to="/gestao/integrations">
                Integrações
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {/* TODO - Permissões */}
        <SubMenu
          key="/relatorios"
          title="Relatórios"
          icon={<i className="far fa-file" />}
        >
          <Menu.Item key="/relatorios/frequency">
            <NavLink to="/relatorios/frequency">Cartão Ponto</NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/totals">
            <NavLink to="/relatorios/totals">Totais</NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/tax-files">
            <NavLink to="/relatorios/tax-files">Arquivos Fiscais</NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/employees">
            <NavLink to="/relatorios/employees">Funcionários</NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/mirror-point">
            <NavLink to="/relatorios/mirror-point">Espelho Ponto</NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/hour-bank-statement">
            <NavLink to="/relatorios/hour-bank-statement">
              Extrato de Banco de Horas
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/absenteeism">
            <NavLink to="/relatorios/absenteeism">
             Absenteísmo
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/relatorios/occurrences">
            <NavLink to="/relatorios/occurrences">
             Ocorrências
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
  }
}

export default connect(mapStateToProps)(withRouter(MainNavigation))
