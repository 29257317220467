import React, { Component, Fragment } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, DatePicker, Form, Card, Input, Checkbox, message, Modal, Tabs, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { annualLeaveService, departmentsService, companiesService } from "./../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
	HourPickerWithMask,
	UIUpload,
} from "./../../components";

import PeriodsTable from "./tables/periods"
import ModalRecalculate from './ModalRecalculate';

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			tipo_controle_extra: null,
			nao_multiplicar_pelo_percentual: null,
			controle_faltas: null,
			isLoading: true,
			isSending: false,
			uuid: 0,
			periods: [],
			hasEmployees: false,
			
			data: [],

			companies: [],
			companiesIsLoading: false,
			departments: [],
			departmentsIsLoading: false,

			recalcularModalVisible: false,
			formToCompare: {},
		};

		this._axiosCancelCompaniesToken = null;
		this._axiosCancelDepartmentsToken = null;
	}

	onChangeChecked = (checkboxName, checked) => {
		if (checkboxName == "Selecionar Todos") {
			const body = {
				extra_domingo: checked,
				extra_feriado: checked,
				extra_folga: checked,
				extra_sabado: checked,
				extra_uteis: checked,
			}

			this.form.setFieldsValue(body);

			return;
		}

		const checkAllAreChecked = [
			this.form.getFieldValue("extra_domingo") == true,
			this.form.getFieldValue("extra_feriado") == true,
			this.form.getFieldValue("extra_folga") == true,
			this.form.getFieldValue("extra_sabado") == true,
			this.form.getFieldValue("extra_uteis") == true,
		]

		this.form.setFieldValue("selecionar_todos", !checkAllAreChecked.includes(false));
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		annualLeaveService.show({ uuid })
			.then((response) => {
				let item = response.data.data;
				let { vinculo_funcionario_banco_horas } = item || {};
				let employees = vinculo_funcionario_banco_horas;

				if (employees.length > 0) {
					this.setState({
						hasEmployees: true
					})
				} else {
					this.setState({
						hasEmployees: false
					})
				}

				this.setState({
					isLoading: false,
					periods: item.banco_horas_vigencia ?? [],
					companies: item?.companies ?? [],
					departments: item?.departments ?? [],
				}, () => {
					// Fill form
					this.descricao && this.descricao.focus();

					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fetchCompanies = () => {
		if (this._axiosCancelCompaniesToken) {
			this._axiosCancelCompaniesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAutocomplete({
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
			.then((response) => {
				this.setState({
					companies: response.data.data,
					companiesIsLoading: false,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value) => {
		if (this._axiosCancelDepartmentsToken) {
			this._axiosCancelDepartmentsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				departmentsIsLoading: false,
				departments: [],
			});

			return false;
		}

		this.setState({
			departmentsIsLoading: true,
		});

		let params = {
			search: value,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelDepartmentsToken.token,
			empresas_ids: this.form.getFieldValue("companies").includes("todos") ? [] : this.form.getFieldValue("companies"),
		}

		departmentsService.getAutocomplete({
			...params
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onCompanyChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("companies", value.filter((item) => item !== "todos"));
		}

		this.setState({
			departments: [],
			departmentsIsLoading: false,
		});

		this.form.setFieldValue("departments", ["todos"]);
	};

	onCompanySelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("companies", ["todos"]);
		}
	};

	onDepartmentChange = (value) => {
		if (value.length > 1 && value.includes("todos")) {
			this.form.setFieldValue("departments", value.filter((item) => item !== "todos"));
		}
	};

	onDepartmentSelect = (value) => {
		if (value === "todos") {
			this.form.setFieldValue("departments", ["todos"]);
		}
	};

	fillForm = (data) => {
		let faixas = [];

		if (data.multiplicar_percentual_domingo == 1) {
			faixas.push("domingo")
		}
		if (data.multiplicar_percentual_folga == 1) {
			faixas.push("folga")
		}
		if (data.multiplicar_percentual_uteis == 1) {
			faixas.push("uteis")
		}
		if (data.multiplicar_percentual_feriado == 1) {
			faixas.push("feriado")
		}
		if (data.multiplicar_percentual_sabado == 1) {
			faixas.push("sabado")
		}

		const formValues = {
			descricao: data.descricao,
			periods: this.state.periods,
			is_active: data.is_active,
			tipo_controle_extra: data.tipo_controle_extra,
			controle_faltas: data.controle_faltas,
			controle_atraso: data.controle_atraso,
			nao_multiplicar_pelo_percentual: data.nao_multiplicar_pelo_percentual,
			extra_domingo: data.extra_domingo,
			extra_feriado: data.extra_feriado,
			extra_folga: data.extra_folga,
			extra_sabado: data.extra_sabado,
			extra_uteis: data.extra_uteis,
			companies: (data.companies && data.companies?.length > 0) ? data.companies.map((item) => item.id) : ["todos"],
			departments: (data.companies && data.departments?.length > 0) ? data.departments.map((item) => item.id) : ["todos"],
			limite_maximo_de_faltas: data.limite_maximo_de_faltas.substr(0, 5),
			limite_maximo_de_extras: data.limite_maximo_de_extras.substr(0, 5),
			faixas_de_horario: faixas,
			multiplicar_percentual_domingo: false,
			multiplicar_percentual_folga: false,
			multiplicar_percentual_uteis: false,
			multiplicar_percentual_feriado: false,
			multiplicar_percentual_sabado: false,
		};

		this.onChangeChecked("", true);
		
		this.form.setFieldsValue(formValues);

		this.setState({
			controle_faltas: data.controle_faltas,
			tipo_controle_extra: data.tipo_controle_extra,
			nao_multiplicar_pelo_percentual: data.nao_multiplicar_pelo_percentual,
			formToCompare: formValues,
		})
	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	checkIfNeedToRecalculate = (dataVigencia) => {
    const fieldHasNoNeedToCallRecalculate = ['descricao'];
    const { formToCompare } = this.state;

    if (!this.form || typeof this.form.getFieldValue !== 'function') {
			return false;
    }

    let periodsChanged = false;
    if (dataVigencia && formToCompare.periods) {
			if (dataVigencia.length !== formToCompare.periods.length) {
				periodsChanged = true;
			} else {
				periodsChanged = dataVigencia.some((currentPeriod, index) => {
					const originalPeriod = formToCompare.periods[index];
					return (
						moment(currentPeriod.vigencia_inicio).format('YYYY-MM-DD') !== moment(originalPeriod.vigencia_inicio).format('YYYY-MM-DD') ||
						moment(currentPeriod.vigencia_final).format('YYYY-MM-DD') !== moment(originalPeriod.vigencia_final).format('YYYY-MM-DD')
					);
				});
			}
    }

    const fieldsModified = Object.keys(formToCompare).reduce((changes, field) => {
			if (field !== 'periods' && !fieldHasNoNeedToCallRecalculate.includes(field)) {
				const fieldValue = this.form.getFieldValue(field);
				const compareValue = formToCompare[field];
				const hasChanged = typeof fieldValue === 'object' && fieldValue !== null ?
					JSON.stringify(fieldValue) !== JSON.stringify(compareValue) :
					fieldValue !== compareValue;
				if (hasChanged) {
					changes[field] = hasChanged;
				}
			}
			return changes;
    }, {});

    const needToRecalculate = periodsChanged || Object.keys(fieldsModified).some(fieldName => {
			return !fieldHasNoNeedToCallRecalculate.includes(fieldName);
    });

    return needToRecalculate;
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			controle_atraso: values?.controle_atraso,
			controle_faltas: values?.controle_faltas,
			descricao: values?.descricao,
			extra_domingo: values?.extra_domingo,
			extra_feriado: values?.extra_feriado,
			extra_folga: values?.extra_folga,
			extra_sabado: values?.extra_sabado,
			extra_uteis: values?.extra_uteis,
			is_active: values?.is_active,
			limite_maximo_de_extras: values?.limite_maximo_de_extras,
			limite_maximo_de_faltas: values?.limite_maximo_de_faltas,
			nao_multiplicar_pelo_percentual: values?.nao_multiplicar_pelo_percentual,
			tipo_controle_extra: values?.tipo_controle_extra,
			companies: values?.companies,
			departments: values?.departments,
			uuid: values?.uuid,
			faixas_de_horario: values?.faixas_de_horario,
			multiplicar_percentual_domingo: values?.multiplicar_percentual_domingo,
			multiplicar_percentual_folga: values?.multiplicar_percentual_folga,
			multiplicar_percentual_uteis: values?.multiplicar_percentual_uteis,
			multiplicar_percentual_feriado: values?.multiplicar_percentual_feriado,
			multiplicar_percentual_sabado: values?.multiplicar_percentual_sabado,
		};

		if (data.faixas_de_horario) {
			data.faixas_de_horario.forEach((faixa) => {
				data[`multiplicar_percentual_${faixa}`] = true;
			})
		}

		// uuid
		data.uuid = uuid;

		if (!(data.companies instanceof Array)) {
			data.companies = [data.companies];
		}

		if (!(data.departments instanceof Array)) {
			data.departments = [data.departments];
		}

		if (data.companies.includes("todos")) {
			data.companies = null;
			data.todas_empresas = true;
		}

		if (data.departments.includes("todos")) {
			data.todas_departamentos = true;
			data.departments = null;
		}

		data.vigencia = this.periodsTable?.state?.periods.map((period) => ({ id: period.id, acao: period.action ?? "criar", vigencia_inicio: moment(period.data_inicial).format('YYYY-MM-DD'), vigencia_final: moment(period.data_final).format('YYYY-MM-DD') }));

		this.periodsTable?.state?.deletedsOriginalPeriods?.length > 0 && this.periodsTable?.state?.deletedsOriginalPeriods?.forEach((period) =>
			data.vigencia.push(
				({ id: period.id, acao: period.action, vigencia_inicio: moment(period.data_inicial).format('YYYY-MM-DD'), vigencia_final: moment(period.data_final).format('YYYY-MM-DD') })
			));

		this.setState(({
			data: data,
		}));

		// Call recalculate modal
		const { hasEmployees } = this.state;

		if (hasEmployees && this.checkIfNeedToRecalculate(data.vigencia)) {
			this.recalculateOpen(data);
			return;
		}
		
		annualLeaveService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});
				this.props.onComplete();

			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	recalculateOpen = ({ uuid }) => {
		this.setState({
			recalcularModalVisible: true,
			isLoading: false,
			isSending: false,
		});

		// On open screen
		this.recalculateScreen.onOpen(uuid);
	};

	recalculateOnClose = () => {
		this.setState({ recalcularModalVisible: false });

		// Success message
		message.success("Registro atualizado com sucesso.");
	};

	recalculateOnComplete = () => {
		this.setState({ recalcularModalVisible: false }, () => {

			// Success message
			message.success("Cálculo realizado com sucesso.");
			// Callback
			this.props.onComplete();
		});
	};

	onCancelRecalculate = () => {
		this.setState({ recalcularModalVisible: false });
		// Callback
		this.props.onComplete();
	}
	
	render() {
		const { visible } = this.props;

		const {
			isLoading,
			isSending,
			periods,
			companies,
			companiesIsLoading,
			departments,
			departmentsIsLoading,
			recalcularModalVisible
		} = this.state;

		return (
			<Fragment>
				<UIDrawerForm
					visible={visible}
					width={700}
					onClose={this.onClose}
					isLoading={isLoading}
					isSending={isSending}
					formId={formId}
					title={`Editar Banco de Horas`}>
					<Form
						ref={el => this.form = el}
						id={formId}
						layout="vertical"
						scrollToFirstError
						onFinish={this.onFinish}
						initialValues={{
						}}>
						<Tabs defaultActiveKey="general">
							<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
								<Row gutter={16}>
									<Col xs={24} md={24}>
										<Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Input ref={el => this.descricao = el} />
										</Form.Item>
									</Col>
									<Col xs={24} md={24}>
										<Form.Item name="is_active" label="Ativo" valuePropName="checked" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Switch />
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Períodos" key="periods">
								<Row gutter={16}>
									<Col xs={24}>
										<PeriodsTable values={periods} ref={el => this.periodsTable = el} />
									</Col>
								</Row>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Extras" key="extras">
								<Row gutter={16}>
									<Col xs={24} md={12}>
										<Form.Item name="tipo_controle_extra" label="Controle de extras:" >
											<Select
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												onChange={(value) => this.setState({ tipo_controle_extra: value })}
												style={{ width: '100%' }}
												showSearch>
												<Select.Option key={"diario"} value="diario">Diário</Select.Option>
												<Select.Option key={"semanal"} value="semanal">Semanal</Select.Option>
												<Select.Option key={"mensal"} value="mensal">Mensal</Select.Option>
												<Select.Option key={"primeira_faixa_entrada"} value="primeira_faixa_entrada">Somente a primeira faixa de extra</Select.Option>
												<Select.Option key={"ultima_faixa_entrada"} value="ultima_faixa_entrada">Somente a última faixa de extra</Select.Option>
												<Select.Option key={"todas_horas_extras"} value="todas_horas_extras">Todas as faixas de horas extras</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item name="nao_multiplicar_pelo_percentual" label="Multiplicar pelo percentual" valuePropName="checked" >
											<Switch onChange={(value) => this.setState({ nao_multiplicar_pelo_percentual: value })} />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									{(this.state.tipo_controle_extra == "diario" ||
										this.state.tipo_controle_extra == "semanal" ||
										this.state.tipo_controle_extra == "mensal"
									) && (
											<Col md={12}>
												<HourPickerWithMask name="limite_maximo_de_extras" label="Limite máximo de extras:" required={true} />
											</Col>
										)}
									{this.state.nao_multiplicar_pelo_percentual == true
										&& (
											<Col md={12}>
												<Form.Item name="faixas_de_horario" label="Faixas de horário:">
													<Select
														mode="multiple"
														optionFilterProp="children"
														filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
														allowClear
														placeholder="Selecione o(s) campo(s)"
														showSearch>
														<Select.Option value="domingo">Domingo</Select.Option>
														<Select.Option value="feriado">Feriado</Select.Option>
														<Select.Option value="folga">Folga</Select.Option>
														<Select.Option value="sabado">Sábado</Select.Option>
														<Select.Option value="uteis">Úteis</Select.Option>
													</Select>
												</Form.Item>
											</Col>
										)}
								</Row>
								<Row gutter={16} style={{ marginBottom: '20px' }}>
									<Col xs={24} md={12}>
										Ignorar faixa de extras:
									</Col>
								</Row>
								<Card>
									<Form.Item valuePropName="checked" name="selecionar_todos">
										<Checkbox
											onChange={(e) => this.onChangeChecked("Selecionar Todos", e.target.checked)}
											style={{ marginBottom: '20px' }}
										>
											Selecionar todos
										</Checkbox>
									</Form.Item>
									<Row gutter={16}>
										<Col xs={24} md={8}>
											<Form.Item name="extra_domingo" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Domingo</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item name="extra_feriado" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Feriado</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item name="extra_folga" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Folga</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item style={{ marginBottom: '10px' }} name="extra_sabado" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Sábado</Checkbox>
											</Form.Item>
										</Col>
										<Col xs={24} md={8}>
											<Form.Item style={{ marginBottom: '10px' }} name="extra_uteis" valuePropName="checked" >
												<Checkbox onChange={(e) => this.onChangeChecked("Campo qualquer", e.target.checked)}  >Úteis</Checkbox>
											</Form.Item>
										</Col>
									</Row>
								</Card>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Faltas" key="faults">
								<Row gutter={16}>
									<Col xs={24} md={12}>
										<Form.Item name="controle_faltas" label="Controle de faltas:" >
											<Select
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												onChange={(value) => this.setState({ controle_faltas: value })}
												showSearch>
												<Select.Option value="diario">Diário</Select.Option>
												<Select.Option value="mensal">Mensal</Select.Option>
												<Select.Option value="nenhuma_hora_falta">Nenhuma hora falta</Select.Option>
												<Select.Option value="semanal">Semanal</Select.Option>
												<Select.Option value="todas_horas_faltas">Todas as horas falta</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									{(this.state.controle_faltas != "nenhuma_hora_falta" && this.state.controle_faltas != "todas_horas_faltas") && (
										<Col md={12}>
											<HourPickerWithMask name="limite_maximo_de_faltas" label="Limite máximo de faltas:" required={true} />
										</Col>
									)}
								</Row>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Atrasos" key="dalays">
								<Col xs={24} md={12}>
									<Form.Item name="controle_atraso" label="Controle de atrasos:" >
										<Select
											ref={el => this.sexo = el}
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											style={{ width: '100%' }}
											showSearch>
											<Select.Option value="nenhuma_hora_atraso">Nenhuma hora atraso</Select.Option>
											<Select.Option value="todas_horas_atraso">Todas as horas atraso</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Tabs.TabPane>
							<Tabs.TabPane forceRender tab="Vínculos" key="ties">
								<Row>
									<Col xs={24}>
										<Form.Item name="companies" label="Empresa" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												mode="multiple"
												optionFilterProp="children"
												filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												placeholder="Selecione a(s) empresa(s)"
												notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchCompanies}
												onChange={this.onCompanyChange}
												onSelect={this.onCompanySelect}
												showSearch>
												<Select.Option value="todos">Todas</Select.Option>
												{companies.map((item, index) => (
													<Select.Option key={index} value={item.id}>
														{item.nome}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col xs={24}>
										<Form.Item name="departments" label="Departamento" rules={[{ required: true, message: "Campo obrigatório." }]}>
											<Select
												mode="multiple"
												optionFilterProp="children"
												filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
												allowClear
												placeholder="Selecione o(s) departamento(s)"
												notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
												onSearch={this.fetchDepartments}
												onChange={this.onDepartmentChange}
												onSelect={this.onDepartmentSelect}
												showSearch>
												<Select.Option value="todos">Todos</Select.Option>
												{departments.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>
						</Tabs>
					</Form>
				</UIDrawerForm>
				<ModalRecalculate
					ref={el => this.recalculateScreen = el}
					visible={recalcularModalVisible}
					onComplete={this.recalculateOnComplete}
					onClose={this.recalculateOnClose}
					onCancel={this.onCancelRecalculate}
					data={this.state.data}
				/>
			</Fragment>
		)
	}
}

export default Edit;
