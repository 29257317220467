import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import QueueAnim from "rc-queue-anim";

import { authService } from "../../redux/services";

class ResetPassword extends Component {
	constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    this.state = {
			isSending: false,
			email: params.get("email") || '', 
			token: params.get("token") || '',
			cliente: params.get("cliente"),
    };
	}

    resetFields = () => {
			this.form.resetFields();
    };

    componentDidMount() {
			if (!this.state.token || !this.state.email) {
				Modal.error({
					title: "Erro",
					content: "Token ou email ausente. Solicite uma nova redefinição de senha.",
					onOk: () => {
						this.props.history.push("/");
					},
				});
			}
    }

    onFinish = (values) => {
			const { cliente } = this.state;
			
			this.setState({ isSending: true });
			values['x-header-cliente'] = cliente;
			const payload = {
				...values,
				token: this.state.token,
				email: this.state.email,
			};

			console.log(payload);
			authService
				.passwordReset(payload)
				.then((response) => {
						this.setState({ isSending: false });
						this.resetFields();
						message.success(response.data.message);
				})
				.catch((data) => {
					this.setState({ isSending: false });
					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
			});
    };

    validatePassword = (_, value) => {
			if (!value || this.form.getFieldValue("password") === value) {
				return Promise.resolve();
			}
			return Promise.reject(new Error("As senhas não são iguais."));
    };

    render() {
			return (
				<QueueAnim className="site-content-inner">
					<div className="page-content" key="1">
						<h1 className="page-title recovery">Recuperar senha</h1>
						<Form
							ref={(el) => (this.form = el)}
							layout="vertical"
							scrollToFirstError
							onFinish={this.onFinish}
						>
							<Form.Item
								name="password"
								rules={[
									{ required: true, message: "Informe uma senha." },
									{ min: 6, message: "A senha deve ter pelo menos 6 caracteres." },
								]}
							>
								<Input.Password
										prefix={<i className="fal fa-lock" />}
										placeholder="Informe a nova senha"
								/>
							</Form.Item>

							<Form.Item
								name="password_confirmation"
								dependencies={["password"]}
								rules={[
										{ required: true, message: "Confirme a senha." },
										{ validator: this.validatePassword },
								]}
							>
								<Input.Password
									prefix={<i className="fal fa-lock" />}
									placeholder="Confirme a nova senha"
								/>
							</Form.Item>

							<Row gutter={16} align="middle">
									<Col xs={12}>
										<Link to="/">
											<i className="fal fa-chevron-left" style={{ marginRight: 8 }} />
											Voltar
										</Link>
									</Col>
									<Col xs={12}>
										<Button
											type="primary"
											htmlType="submit"
											size="large"
											block
											loading={this.state.isSending}
										>
											Enviar
										</Button>
									</Col>
							</Row>
						</Form>
					</div>
				</QueueAnim>
			);
	}
}

export default ResetPassword;
