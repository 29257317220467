import AboutApp from "./../screens/AboutApp";
import Account from "./../screens/Account";
import AccountPassword from "./../screens/AccountPassword";
import AnnualLeave from "./../screens/AnnualLeave";
import ClosingCalculationPeriod from "../screens/ClosingCalculationPeriod";
import Companies from "./../screens/Companies";
import DailyPoint from "../screens/DailyPoint";
import DailyPointPrint from "../screens/Impressao/DailyPoint";
import Departments from "../screens/Departments";
import Dsr from "./../screens/Dsr";
import Employees from "./../screens/Employees";
import EmployeesReport from "./../screens/EmployeesReport";
import Equipments from "./../screens/Equipments";
import Faq from "./../screens/Faq";
import Frequency from "./../screens/Frequency";
import Functions from "./../screens/Functions";
import Holidays from "./../screens/Holidays";
import Home from "./../screens/Home";
import HourBankStatement from "./../screens/HourBankStatement";
import HourBankStatementPrint from "./../screens/Impressao/HourBankStatement";
import Absenteeism from "./../screens/Absenteeism";
import AbsenteeismPrint from "./../screens/Impressao/Absenteeism";
import Occurrences from "./../screens/Occurrences";
import OccurrencesPrint from "./../screens/Impressao/Occurrences";
import ImportAdfFile from "./../screens/ImportAdfFile";
import Integrations from "./../screens/Integrations";
import JourneyException from "../screens/JourneyException";
import Journeys from "./../screens/Journeys";
import Time from "./../screens/Time";
import Justifications from "./../screens/Justifications";
import Login from "./../screens/Login";
import Logs from "./../screens/Logs";
import MirrorPoint from "./../screens/MirrorPoint";
import MirrorPointPrint from "./../screens/Impressao/MirrorPoint";
import Overtime from "./../screens/Overtime";
import PartialJustifications from "./../screens/PartialJustifications";
import Perimeters from "./../screens/Perimeters";
import PrivacyPolicy from "./../screens/PrivacyPolicy";
import Professionals from "./../screens/Professionals";
import ProfessionalsDeleted from "./../screens/ProfessionalsDeleted";
import PushCity from "./../screens/PushCity";
import PushGeneral from "./../screens/PushGeneral";
import PushState from "./../screens/PushState";
import PushUser from "./../screens/PushUser";
import RecalculateSchedules from "../screens/RecalculateSchedules";
import RecoveryPassword from "./../screens/RecoveryPassword";
import ResetPassword from "./../screens/ResetPassword";
import Removals from "../screens/Removals";
import Resales from "./../screens/Resales";
import RolesAndPermissions from "./../screens/RolesAndPermissions";
import SettingsGeneral from "./../screens/SettingsGeneral";
import SettingsNotifications from "./../screens/SettingsNotifications";
import SystemLog from "./../screens/SystemLog";
import TaxFiles from "./../screens/TaxFiles";
import Tenants from "./../screens/Tenants";
import TenantsDeleted from "./../screens/TenantsDeleted";
import TenantUserFunctions from "./../screens/TenantUserFunctions";
import TenantUserTypes from "./../screens/TenantUserTypes";
import TermOfUse from "./../screens/TermOfUse";
import TimeBankMaintenance from "../screens/TimeBankMaintenance";
import TimeCard from "./../screens/TimeCard";
import TimeCardPrint from "./../screens/Impressao/TimeCard";
import Totals from "./../screens/Totals";
import TotalsPrint from "./../screens/Impressao/Totals";
import Users from "./../screens/Users";

// -----------------------------------------------------------------------------
// Routes
// -----------------------------------------------------------------------------
/**
 * Private routes for registered user access
 *
 * @type {Array}
 */
export const PRIVATE_ROUTES = [
	// Home
	{
		path: "/",
		component: Home,
		exact: true,
	},
	// Account
	{
		path: "/account",
		component: Account,
		exact: true,
	},
	{
		path: "/account/password",
		component: AccountPassword,
		exact: true,
	},
	// Administrator
	{
		path: "/administrator/logs",
		component: Logs,
		exact: true,
	},
	{
		path: "/administrator/roles-and-permissions",
		component: RolesAndPermissions,
		exact: true,
	},
	{
		path: "/administrator/system-log",
		component: SystemLog,
		exact: true,
	},
	{
		path: "/administrator/tenant-user-functions",
		component: TenantUserFunctions,
		exact: true,
	},
	{
		path: "/administrator/tenant-user-types",
		component: TenantUserTypes,
		exact: true,
	},
	{
		path: "/administrator/users",
		component: Users,
		exact: true,
	},
	// Cadastros
	{
		path: "/cadastro/overtime",
		component: Overtime,
		exact: true,
	},
	{
		path: "/cadastro/dsr",
		component: Dsr,
		exact: true,
	},
	{
		path: "/cadastro/employees",
		component: Employees,
		exact: true,
	},
	{
		path: "/cadastro/departments",
		component: Departments,
		exact: true,
	},
	{
		path: "/cadastro/functions",
		component: Functions,
		exact: true,
	},
	{
		path: "/cadastro/holidays",
		component: Holidays,
		exact: true,
	},
	{
		path: "/cadastro/justifications",
		component: Justifications,
		exact: true,
	},
	{
		path: "/cadastro/annual-leave",
		component: AnnualLeave,
		exact: true,
	},
	{
		path: "/cadastro/journeys",
		component: Journeys,
		exact: true,
	},
	{
		path: "/cadastro/time",
		component: Time,
		exact: true,
	},
	{
		path: "/cadastro/perimeters",
		component: Perimeters,
		exact: true,
	},
	// Gestão
	{
		path: "/gestao/dailypoint",
		component: DailyPoint,
		exact: true,
	},
	{
		path: "/gestao/timecard",
		component: TimeCard,
		exact: true,
	},
	{
		path: "/gestao/lancamentos-coletivos/partial-justifications",
		component: PartialJustifications,
		exact: true,
	},
	{
		path: "/gestao/lancamentos-coletivos/afastamentos",
		component: Removals,
		exact: true,
	},
	{
		path: "/gestao/lancamentos-coletivos/fechamento-periodo-calculo",
		component: ClosingCalculationPeriod,
		exact: true,
	},

	{
		path: "/gestao/lancamentos-coletivos/recaulcular-horarios",
		component: RecalculateSchedules,
		exact: true,
	},
	{
		path: "/gestao/lancamentos-coletivos/excecao-de-jornada",
		component: JourneyException,
		exact: true,
	},
	{
		path: "/gestao/lancamentos-coletivos/manutencao-de-banco-de-horas",
		component: TimeBankMaintenance,
		exact: true,
	},
	{
		path: "/gestao/import-afd-file",
		component: ImportAdfFile,
		exact: true,
	},
	{
		path: "/gestao/integrations",
		component: Integrations,
		exact: true,
	},
	// Relatórios
	{
		path: "/relatorios/frequency",
		component: Frequency,
		exact: true,
	},
	{
		path: "/relatorios/totals",
		component: Totals,
		exact: true,
	},
	{
		path: "/relatorios/tax-files",
		component: TaxFiles,
		exact: true,
	},
	{
		path: "/relatorios/employees",
		component: EmployeesReport,
		exact: true,
	},
	{
		path: "/relatorios/mirror-point",
		component: MirrorPoint,
		exact: true,
	},
	{
		path: "/relatorios/hour-bank-statement",
		component: HourBankStatement,
		exact: true,
	},
	{
		path: "/relatorios/absenteeism",
		component: Absenteeism,
    	exact: true,
	},
	{
		path: "/relatorios/occurrences",
		component: Occurrences,
		exact: true,
	},
	// Impressão
	{
		path: "/impressao/dailypoint",
		component: DailyPointPrint,
		exact: true,
	},
	{
		path: "/impressao/timecard",
		component: TimeCardPrint,
		exact: true,
	},
	{
		path: "/impressao/totals",
		component: TotalsPrint,
		exact: true,
	},
	{
		path: "/impressao/mirror-point",
		component: MirrorPointPrint,
		exact: true,
	},
	{
		path: "/impressao/hour-bank-statement",
		component: HourBankStatementPrint,
		exact: true,
	},
	{
		path: "/impressao/absenteeism",
		component: AbsenteeismPrint,
    	exact: true,
	},
	{
		path: "/impressao/occurrences",
		component: OccurrencesPrint,
		exact: true,
	},
	// Institutional
	{
		path: "/institutional/about-app",
		component: AboutApp,
		exact: true,
	},
	{
		path: "/institutional/faq/:type(funcionario|empresa)",
		component: Faq,
		exact: true,
	},
	{
		path: "/institutional/privacy-policy/:type(funcionario|empresa)",
		component: PrivacyPolicy,
		exact: true,
	},
	{
		path: "/institutional/terms-of-use/:type(funcionario|empresa)",
		component: TermOfUse,
		exact: true,
	},
	// Lists
	{
		path: "/list/companies",
		component: Companies,
		exact: true,
	},
	{
		path: "/list/resales",
		component: Resales,
		exact: true,
	},
	{
		path: "/list/equipments",
		component: Equipments,
		exact: true,
	},
	{
		path: "/list/professionals",
		component: Professionals,
		exact: true,
	},
	{
		path: "/list/tenants",
		component: Tenants,
		exact: true,
	},
	// Lists deleted
	{
		path: "/list-deleted/professionals",
		component: ProfessionalsDeleted,
		exact: true,
	},
	{
		path: "/list-deleted/tenants",
		component: TenantsDeleted,
		exact: true,
	},
	// Push
	{
		path: "/push/city",
		component: PushCity,
		exact: true,
	},
	{
		path: "/push/general",
		component: PushGeneral,
		exact: true,
	},
	{
		path: "/push/user",
		component: PushUser,
		exact: true,
	},
	{
		path: "/push/state",
		component: PushState,
		exact: true,
	},
	// Settings
	{
		path: "/settings/general",
		component: SettingsGeneral,
		exact: true,
	},
	{
		path: "/settings/notifications",
		component: SettingsNotifications,
		exact: true,
	},
];

/**
 * Session routes that if logged in need to be redirected to the dashboard
 *
 * @type {Array}
 */
export const SESSION_ROUTES = [
	// Login
	{
		path: "/login",
		component: Login,
		exact: true,
	},
	// Recovery password
	{
		path: "/recovery-password",
		component: RecoveryPassword,
		exact: true,
	},
	// Reset password
	{
		path: "/reset-password",
		component: ResetPassword,
		exact: true,
	},
];
