import { osName, browserName } from "./../helpers/client";

// -----------------------------------------------------------------------------
// General
// -----------------------------------------------------------------------------
export const IS_DEBUG = process.env.NODE_ENV === 'development';
export const CLIENT_DATA = {
	os_name: osName(),
	browser_name: browserName(),
};

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------
// export const API_URL = IS_DEBUG ? "https://api-empresa.byponto.com.br/api/v1/admin/" : "https://api-empresa.byponto.com.br/api/v1/admin/";
// export const API_URL_BYPONTO = IS_DEBUG ? "https://api.byponto.com.br/api/v1/app/" : "https://api.byponto.com.br/api/v1/app/";
//export const API_URL = IS_DEBUG ? "http://api-empresa.byponto.local/api/v1/admin/" : "https://api-empresa.byponto.com.br/api/v1/admin/";
export const API_URL = `${process.env.REACT_APP_API_EMPRESA_URL}/api/v1/admin/`;
export const API_AFD_URL = `${process.env.REACT_APP_API_EMPRESA_AFD_URL}/api/v1/admin/`;
export const API_URL_BYPONTO = `${process.env.REACT_APP_API_URL}/api/v1/app/`;

// -----------------------------------------------------------------------------
// Errors
// -----------------------------------------------------------------------------
export const API_ERRO_TYPE_VALIDATION = "validation";
export const API_ERRO_TYPE_API = "api";
export const API_ERRO_TYPE_SERVER = "server";
export const API_ERRO_TYPE_CONNECTION = "connection";
export const API_ERRO_TYPE_OTHER = "other";
export const API_ERRO_TYPE_ACCESS_TOKEN = "access_token";
export const API_ERRO_TYPE_CANCEL = "cancel";
