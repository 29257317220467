import React, { Component, Fragment } from "react";
import { Modal, Spin, Empty, Button } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { timeCardService, columnsService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
			isLoading: false,
			data: [],
			dataTotal: {},

			joinColumns: [],
			dynamicColumns: [],
			overtimeColumns: [],
			// Total
			dynamicTotalColumns: [],
			overtimeTotalColumns: [],

			columnsList: [],
			maxJoinColumns: 0,

			dataDia: "",
			empresa_id: 0,
			empresa_nome: "",

			funcao_id: 0,
			funcao_nome: "",

			funcionario_id: 0,
			funcionario_nome: "",

			departamento_id: 0,
			departamento_nome: "",

			jornada_id: 0,
			jornada_descricao: "",
			dailyPoint: true,
		};
	}

	componentDidMount() {
		document.body.classList.add("page-print", 'page-print-timecard', 'page-print-daily');
		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove("page-print", 'page-print-timecard', 'page-print-daily');
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = new URLSearchParams(this.props.location.search);

		let data = {};

		if (params.has("data")) {
			data.data = params.get("data");
		} else {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Data não encontrada!",
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			});
			return false;
		}

		const filters = ['empresa_id', 'departamento_id', 'jornada_id', 'funcao_id', 'funcionario_id'];

		const filterData = {}
		filters.forEach((filter) => {
			if (params.has(filter)) {
				filterData[filter] = params.get(filter);
			}
		});

		data = {
			...data,
			...filterData
		};

		this.setState({
			...filterData
		});

		timeCardService
			.getAllDailyPoint(data)
			.then((response) => {
				this.setState({
						isLoading: false,
						dataDia: response.data.data[0]?.data || "",
						empresa_nome: response.data.data[0]?.contrato?.empresa?.nome || "",
						funcao_nome: response.data.data[0]?.contrato?.funcao_nome || "",
						departamento_nome: response.data.data[0]?.departamento.nome || "",
						jornada_descricao: response.data.data[0]?.contrato?.jornada_descricao || "",
						data: response.data.data.map((item) => ({
							...item,
							_ajuste_horas_falta: this.formatTime(item.ajuste_horas_falta),
						})),
					},
					() => {
						columnsService
							.show({empresa_id: this.state.empresa_id || response.data.data[0]?.contrato?.empresa_id})
							.then((response) => {
								this.setState(
									{
										columnsList: response.data.data[0],
									},
									() => {
										this.getJoinColumns();
										this.getDynamicColumns();
										this.getOvertimeColumns();
										
										// Total
										timeCardService.getTotalDailyPoint({ ...data, dailyPoint: this.state.dailyPoint })
										.then((response) => {
											this.setState({
												dataTotal: {
													...response.data,
													_total_ajustado: this.formatTime(response.data.total_ajustado),
												}
											}, () => {
													this.getDynamicTotalColumns();
													this.getOvertimeTotalColumns();

													setTimeout(() => {
														// Print
														document.title = 'Ponto Diario';
														if(this.state.data.length > 0) {
															// window.print with a custom file name
															window.print();
															// window.close();
														}
													}, 1000);
												}
											);
										})
										.catch((data) => {
											Modal.error({
												title: "Ocorreu um erro!",
												content: String(data),
											});
										});
									}
								);
							})
							.catch((data) => {
								Modal.error({
									title: "Ocorreu um erro!",
									content: String(data),
								});
							});
					}
				);
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	formatTime = (time) => {
		if (time < 0) {
			return `-${moment()
				.startOf("day")
				.add(Math.abs(time), "minutes")
				.format("HH:mm")}`;
		}

		return moment().startOf("day").add(time, "minutes").format("HH:mm");
	};

	formatTotalDataSaldo = (timeString) => {
		const parts = timeString.split(':');
		return parts[0] + ':' + parts[1];
	};

	formatBatidaStatus = (item, batida) => {
		// Status de dia todo
		if(item?.pontoajuste?.justificativa_id) {
			return item?.pontoajuste?.justificativa?.nome ?? "Justificativa";
		} else if(item.folga) {
			return "Folga";
		} else if(item?.feriado_id) {
			return "Feriado";
		} else if(item?.dia_especial) {
			return "Dia especial";
		}

		// Status individuais
		if(batida) {
			if(batida?.ponto_ajustes_justificativa_falta_id) {
				return batida?.ponto_ajustes_justificativa_falta?.justificativa?.nome ?? "Justificativa";
			}
		}

		if (item?.afastamento?.justificativa) {
			return item?.afastamento?.justificativa?.nome ?? 'Justificativa'
		}
		
		return null;
	}

	getJoinColumns = () => {
		const {data} = this.state;

		let columns = [];
		let max = 0;

		data.forEach((item) => {
			let totalBatidasJornada = item.jornadadiasemana?.jornada_batidas?.length ?? 0;
			let totalBatidasCartao  = item.cartaopontobatida?.length ?? 0;

			if( totalBatidasJornada )
			{
				// Pega a sequencia do ultima batida, api ja retorna por sequencia asc
				const maxJornada = item.jornadadiasemana.jornada_batidas[totalBatidasJornada - 1].sequencia;

				if( maxJornada > max )
				{
					max = maxJornada;
				}
			}

			if( totalBatidasCartao )
			{
				// Pega a sequencia do ultima batida, api ja retorna por sequencia asc
				const maxBatida = item.cartaopontobatida[totalBatidasCartao - 1].sequencia;

				if( maxBatida > max )
				{
					max = maxBatida;
				}
			}
		});

		this.setState({
			maxJoinColumns: max,
		});

		[...Array(max).keys()].map((index) => {
			columns.push(
				{
					title: "Ent. " + (index + 1),
					width: 80,
					className: "no-ellipsis no-padding-horizontal text-center",
					render: (item, row_index) => {
						const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
						const status = this.formatBatidaStatus(item, batidaIndex === -1 ? null : item[batidaIndex]);

						return (
							<div className="cell-entry-exit-data">{status ?? item.cartaopontobatida[index]?.entrada_batida ?? ""}</div>
						);
					},
				},
				{
					title: "Saí. " + (index + 1),
					width: 80,
					className: "no-ellipsis no-padding-horizontal text-center",
					render: (item, row_index) => {
						const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
						const status = this.formatBatidaStatus(item, batidaIndex === -1 ? null : item[batidaIndex]);

						return (
							<div className="cell-entry-exit-data">{status ?? item.cartaopontobatida[index]?.saida_batida ?? ""}</div>
						);
					},
				}
			);
		});

		this.setState({
			joinColumns: columns,
		});
	};

	getDynamicColumns = () => {
		const {columnsList} = this.state;
		let columns         = [];

		if( columnsList?.apuracao_normal )
		{
			columns.push({
				title    : 'Normal',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.horas_normal === "00:00" ? "" : item.horas_normal}</div>
			});
		}

		if( columnsList?.apuracao_faltas )
		{
			columns.push({
				title    : 'Faltas',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.horas_falta === "00:00" ? "" : item.horas_falta}</div>
			});
		}

		if( columnsList?.apuracao_carga )
		{
			columns.push({
				title    : 'Carga',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.carga_horaria_jornada === "00:00" ? "" : item.carga_horaria_jornada}</div>
			});
		}

		if( columnsList?.apuracao_atraso )
		{
			columns.push({
				title    : 'Atraso',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.horas_atraso === "00:00" ? "" : item.horas_atraso}</div>
			});
		}

		if( columnsList?.apuracao_falta_dia )
		{
			columns.push({
				title: 'Falta Dia',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.falta_dia_inteiro ? "1" : ""}</div>
			});
		}

		if( columnsList?.apuracao_ajuste )
		{
			columns.push({
				title    : 'Ajuste',
				width    : 45,
				className: "text-center",
				render   : (item) => {
					return (
						<div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}} onClick={() => this.ajustesOpen(item)}>{item._ajuste_horas_falta === "00:00" ? "" : item._ajuste_horas_falta}</div>
					)
				}
			});
		}

		if( columnsList?.apuracao_dsr )
		{
			columns.push({
				title    : 'Dsr',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.dsr_valor === "00:00" ? "" : item.dsr_valor}</div>
			});
		}

		if( columnsList?.apuracao_dsr_debito )
		{
			columns.push({
				title    : 'Dsr Déb.',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.dsr_debito === "00:00" ? "" : item.dsr_debito}</div>
			});
		}

		if( columnsList?.apuracao_adiconal_noturno )
		{
			columns.push({
				title    : 'Adic. Noturno',
				width    : 120,
				className: "text-center",
				render   : (item) => <div>{item.adicional_noturno === "00:00" ? "" : item.adicional_noturno}</div>
			});
		}

		if( columnsList?.apuracao_abono )
		{
			columns.push({
				title    : 'Abono',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.horas_abono === "00:00" ? "" : item.horas_abono}</div>
			});
		}

		if( columnsList?.apuracao_extra )
		{
			columns.push({
				title    : 'Extra',
				width    : 45,
				className: "text-center",
				render   : (item) => <div>{item.horas_extra === "00:00" ? "" : item.horas_extra}</div>
			});
		}

		this.setState({
			dynamicColumns: columns,
		});
	}

	getOvertimeColumns = () => {
		const { data, columnsList } = this.state;
		let columns = [];

		// hora_extra_diurna
		// hora_extra_intervalo
		// hora_extra_noturna

		data.forEach((item) => {
			(item.hora_extra?.hora_extra_dados ?? []).forEach((dados) => {
				dados.hora_extra_faixas.forEach((faixa) => {
					const index = columns.findIndex(
						(column) =>
							column.percentual === faixa.percentual &&
							column.periodo === dados.periodo
					);

					if (index === -1) {
						if (
							(columnsList?.hora_extra_diurna && dados.periodo === "diurno") ||
							(columnsList?.hora_extra_intervalo &&
								dados.periodo === "intervalo") ||
							(columnsList?.hora_extra_noturna && dados.periodo === "noturno")
						) {
							columns.push({
								idsFaixa: [faixa.id],
								percentual: faixa.percentual,
								periodo: dados.periodo,
								title:
									"Extra " +
									parseFloat(faixa.percentual) +
									"%" +
									dados.periodo.charAt(0).toUpperCase(),
								width: 110,
								className: "no-ellipsis",
							});
						}
					} else {
						columns[index].idsFaixa.push(faixa.id);
					}
				});
			});
		});

		columns.forEach((column) => {
			column.render = (item) => {
				let value = "";
				item.cartaopontohorasextras.forEach((extra) => {
					if (column.idsFaixa.includes(extra.horas_extras_faixa_id)) {
						value = extra.quantidade;
						return false;
					}
				});

				return <div>{value}</div>;
			};
		});

		if(columnsList?.banco_hora) {
			columns.push({
				title    : 'BCréd',
				width    : 45,
				className: "text-center",
				render: (item) => <div>{item.horas_banco_extras === "00:00" ? "" : item.horas_banco_extras}</div>
			});
	
			columns.push({
				title    : 'BDéb',
				width    : 45,
				className: "text-center",
				render: (item) => <div>{item.horas_banco_falta === "00:00" ? "" : item.horas_banco_falta}</div>
			});
	
			columns.push({
				title    : 'BTotal',
				width    : 45,
				className: "text-center",
				render: (item) => <div>{(item.horas_banco_total === "00:00" ? "" : item.horas_banco_total)}</div>
			});
	
			columns.push({
				title    : 'BSaldo',
				width    : 45,
				className: "text-center",
				render: (item) => <div>{item.horas_banco_saldo === "00:00" ? "00:00" : item.horas_banco_saldo}</div>
			});

			columns.push({
				title    : 'BAjuste',
				width    : 45,
				className: "text-center",
				render: (item) => (
          <div>
            {item.horas_banco_ajustes === '00:00'
              ? ''
              : item.horas_banco_ajustes}
          </div>
        ),
			});
		}

		this.setState({
			overtimeColumns: columns,
		});
	};

	columns = () => {
		return [
			{
				title: "Nome do Funcionário",
				width: 350,
				render: (item) => (
					<div style={{ fontSize: "10px" }}>{item?.funcionario?.nome ?? "-"}</div>
				),
			},
			// {
			// 	title: "Previsto",
			// 	width: 105,
			// 	className: "previsto",
			// 	render: (item) => (
			// 		<div className="inner" style={{ height: '100%', maxHeight: '35px' }}>
			// 			{!item?.folga && !item?.feriado_id && !item?.dia_especial && item?.jornadadiasemana?.jornada_batidas.length > 0
			// 				? item.jornadadiasemana.jornada_batidas.map((batida, index) => (
			// 						<Fragment key={index}>
			// 							<span style={{ fontSize: '8px', marginRight: '5px', whiteSpace: 'nowrap', width: '80px' }}>
			// 								{batida.entrada && batida.saida ? `${batida.entrada} - ${batida.saida}` : ''}
			// 							</span>
			// 							<br />
			// 						</Fragment>
			// 					))
			// 				: null
			// 			}
			// 		</div>
			// 	),
			// },			
			...this.state.joinColumns,
			...this.state.dynamicColumns,
			...this.state.overtimeColumns,
		];
	};

	columnsTotal = () => {
		return [
			{
				title: "Total",
				width: 515 + this.state.maxJoinColumns * 80 * 2,
				render: () => <Fragment></Fragment>,
			},
			...this.state.dynamicTotalColumns,
			...this.state.overtimeTotalColumns,
		];
	};

	getDynamicTotalColumns = () => {
		const { columnsList, dataTotal } = this.state;

		let columns = [];

		if( columnsList?.apuracao_normal )
		{
			columns.push({
				title    : 'Normal',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_normais === "00:00" ? "00:00" : dataTotal.total_horas_normais}</div>
			});
		}

		if( columnsList?.apuracao_faltas )
		{
			columns.push({
				title    : 'Faltas',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_faltas === "00:00" ? "00:00" : dataTotal.total_horas_faltas}</div>
			});
		}

		if( columnsList?.apuracao_carga )
		{
			columns.push({
				title    : 'Carga',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_carga === "00:00" ? "00:00" : dataTotal.total_horas_carga}</div>
			});
		}

		if( columnsList?.apuracao_atraso )
		{
			columns.push({
				title    : 'Atraso',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_atraso === "00:00" ? "00:00" : dataTotal.total_horas_atraso}</div>
			});
		}

		if( columnsList?.apuracao_falta_dia )
		{
			columns.push({
				title    : 'Falta/Dia',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_falta_dia ? dataTotal.total_falta_dia : "0"}</div>
			});
		}

		if( columnsList?.apuracao_ajuste )
		{
			columns.push({
				title    : 'Ajuste',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_ajustado === "00:00" ? "00:00" : dataTotal.total_ajustado}</div>
			});
		}

		if( columnsList?.apuracao_dsr )
		{
			columns.push({
				title    : 'Dsr',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_dsr === "00:00" ? "00:00" : dataTotal.total_dsr}</div>
			});
		}

		if( columnsList?.apuracao_dsr_debito )
		{
			columns.push({
				title    : 'Dsr Déb.',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_dsr_debito === "00:00" ? "00:00" : dataTotal.total_dsr_debito}</div>
			});
		}

		if( columnsList?.apuracao_adiconal_noturno )
		{
			columns.push({
				title    : 'Adic. Noturno',
				width    : 120,
				className: "text-center",
				render   : () => <div>{dataTotal.total_adiconal_noturo === "00:00" ? "00:00" : dataTotal.total_adiconal_noturo}</div>
			});
		}

		if( columnsList?.apuracao_abono )
		{
			columns.push({
				title    : 'Abono',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_abono === "00:00" ? "00:00" : dataTotal.total_abono}</div>
			});
		}

		if( columnsList?.apuracao_extra )
		{
			columns.push({
				title    : 'Extra',
				width    : 80,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_extras === "00:00" ? "00:00" : dataTotal.total_horas_extras}</div>
			});
		}

		this.setState({
			dynamicTotalColumns: columns,
		});
	}

	getOvertimeTotalColumns = () => {
		const { data, dataTotal, columnsList } = this.state;
		let columns = [];
		// hora_extra_diurna
		// hora_extra_intervalo
		// hora_extra_noturna

		data.forEach((item) => {
			(item.hora_extra?.hora_extra_dados ?? []).forEach((dados) => {
				dados.hora_extra_faixas.forEach((faixa) => {
					const index = columns.findIndex((column) => column.percentual === faixa.percentual && column.periodo === dados.periodo);

					if( index === -1 )
					{
						if( (columnsList?.hora_extra_diurna && dados.periodo === "diurno") || (columnsList?.hora_extra_intervalo && dados.periodo === "intervalo") || (columnsList?.hora_extra_noturna && dados.periodo === "noturno") )
						{
							columns.push(
								{
									idsFaixa  : [faixa.id],
									percentual: faixa.percentual,
									periodo   : dados.periodo,
									title     : 'Extra ' + parseFloat(faixa.percentual) + "%" + dados.periodo.charAt(0).toUpperCase(),
									width     : 110,
									className : "no-ellipsis text-left",
								},
							);
						}
					}
					else
					{
						columns[index].idsFaixa.push(faixa.id);
					}
				});
			});
		});

		columns.forEach((column) => {
			column.render = () => {
				let value = "00:00";
				if(dataTotal?.horas_extras) {
					Object.keys(dataTotal.horas_extras).forEach((key) => {
						if( column.idsFaixa.includes(parseInt(key)) )
						{
							value = dataTotal.horas_extras[key];
							return false;
						}
					});
				}

				return (
					<div>{value}</div>
				)
			};
		});

		if(columnsList?.banco_hora) {
			columns.push({
				title    : 'BCréd',
				width    : 100,
				className: "text-center",
				render: () => <div>{dataTotal.bh_horas_extras === "00:00" ? "00:00" : dataTotal.bh_horas_extras}</div>
			});
	
			columns.push({
				title    : 'BDéb',
				width    : 100,
				className: "text-center",
				render: () => <div>{dataTotal.bh_horas_falta === "00:00" ? "00:00" : dataTotal.bh_horas_falta}</div>
			});
	
			columns.push({
				title    : 'BTotal',
				width    : 100,
				className: "text-center",
				render: () => <div>{(dataTotal.bh_horas_total === "00:00" ? "00:00" : dataTotal.bh_horas_total)}</div>
			});
	
			columns.push({
				title    : 'BSaldo',
				width    : 100,
				className: "text-center",
				render: () => <div>{dataTotal?.bh_horas_saldo === "00:00" ? "00:00" : this.formatTotalDataSaldo(dataTotal?.bh_horas_saldo)}</div>
			});

			columns.push({
				title    : 'BAjuste',
				width    : 100,
				className: "text-center",
				render: () => <div>{(dataTotal.bh_horas_ajuste === "00:00" ? "00:00" : dataTotal.bh_horas_ajuste)}</div>
			});
		}

		this.setState({
			overtimeTotalColumns: columns,
		});
	}

	getStyleColumns = (index, col, listColumns) => {
		const isLastColumn = index === listColumns?.length - 1;
		const hasIdsFaixas = 'idsFaixa' in col;
		const isSpecialTitle = ['Falta Dia', 'Dsr Déb.', 'Adic. Noturno'].includes(col.title) || col.title.includes('Extra ');
		const isEntryOrExit = typeof col.title === 'string' && (col.title.includes('Ent.') || col.title.includes('Saí.'));
	
		const styleMap = [
			{ condition: index === 0, className: 'cell-first-column' },
			{ condition: isSpecialTitle && !isLastColumn, className: 'cell-special-title' },
			{ condition: isSpecialTitle && isLastColumn, className: 'cell-special-title-last' },
			{ condition: (isLastColumn || hasIdsFaixas) && !isSpecialTitle, className: 'cell-last-column' },
			{ condition: isEntryOrExit, className: 'cell-entry-exit' },
		];
	
		const classes = ['cell-default'];
	
		styleMap.forEach(({ condition, className }) => {
			if (condition) {
				classes.push(className);
			}
		});
	
		return classes.join(' ');
	};	

	render() {
		const { isLoading, data } = this.state;
		const { user } = this.props;
		const { name: userName } = user || {};

		const listColumns = this.columns();
		const totalsCols = this.columnsTotal()?.length;

 	 	const style = `@page { size: A4 landscape; margin: 0 }`;

		return (
			<Fragment>
				{isLoading ? (
					<div
						style={{
							position: "fixed",
							top: "150px",
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spin
							spinning={isLoading}
							indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
						/>
					</div>
				) : (
					data.length > 0 ? (
						<table className="table-impressao">
							<thead>
								<tr>
									<td className="time-card">
										<header>
											<div className="logo-container">
												<img
													src="/images/logos/logo-complete.svg"
													alt="ByPonto"
													width="150"
												/>
												<div className="logo-title" style={{ float: 'right' }}>
													<div>
														<h1>Ponto Diário</h1>
													</div>
													<div>
														<div>Data:{" "}{moment(this.state.dataDia).format("DD/MM/YYYY")}</div>														
													</div>
													<div className="user-info">
														<h4>{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})</h4>
													</div>
												</div>
											</div>
											<div style={{ marginLeft: '19px' }}>
												{!!this.state.empresa_id && <p className="subtitle"><strong>Empresa:</strong>{" "}{this.state.empresa_nome}</p>}
												{!!this.state.departamento_id && <p className="subtitle"><strong>Departamento:</strong>{" "}{this.state.departamento_nome}</p>}
												{!!this.state.funcao_id && <p className="subtitle"><strong>Função:</strong>{" "}{this.state.funcao_nome}</p>}
												{!!this.state.jornada_id && <p className="subtitle"><strong>Jornada:</strong>{" "}{this.state.jornada_descricao}</p>}
											</div>
										</header>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div className="content">
											<div className="table-container" style={{ marginLeft: '19px', fontSize: '9px' }}>
												<table className="responsive-table">
													<thead>
														<tr>
															{this.columns().map((col, index) => (
																<th 
																	key={index} 
																	className={this.getStyleColumns(index, col, listColumns)}
																>
																	<div className={index === listColumns.length - 1 ? 'last-column-content-center' : ''}>
																		{col.title}	
																	</div>
																</th>
															))}
														</tr>
													</thead>
													<tbody>
														{isLoading ? (
															<tr>
																<td colSpan="100%">Carregando...</td>
															</tr>
															) : data.length > 0 ? (
																data.map((row, rowIndex) => (
																	<tr key={rowIndex}>
																		{this.columns().map((col, colIndex) => (
																			<td 
																				key={colIndex}
																				className={this.getStyleColumns(colIndex, col, listColumns)}
																			>
																				<div 
																					className={colIndex === listColumns.length - 1 ? 'last-column-content-center' : ''}
																				>
																					{col.render(row, rowIndex)}
																				</div>
																			</td>
																		))}
																	</tr>
																))
															) : (
															<tr>
																<td colSpan="100%">Nenhum registro encontrado com esses parâmetros.</td>
															</tr>
															)}
															<tr>
																<td className="negrito-geral">TOTAIS</td>
																{(() => {
																		const maxCartaoPontoBatidaLength = data.reduce((max, item) => {
																			return Math.max(max, item.cartaopontobatida ? item.cartaopontobatida.length : 0);
																		}, 0);
																		const additionalCells = [];
																		for (let i = 1; i < (maxCartaoPontoBatidaLength * 2); i++) {
																			additionalCells.push(<td key={`additional-${i}`}></td>);
																		}

																		return additionalCells;
																})()}
																{this.columnsTotal().map((col, index) => {
																	const content = col.render ? col.render() : <Fragment></Fragment>;
																	return (
																		<td 
																			key={index} 
																			colSpan={col.colSpan || 1} 
																			className="negrito-geral"
																			style={ index === 0 || index === totalsCols - 1 ? { textAlign: 'left' } : { textAlign: 'center' } }
																		>
																			<div className={index === totalsCols - 1 ? 'last-column-content-center' : 'text-center'}>
																				{content}
																			</div>
																		</td>
																	);
																})}
															</tr>
														</tbody>
												</table>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td>
										<div class="footer-space">&nbsp;</div>
									</td>
								</tr>
							</tfoot>
						</table>
					) : (
						<Empty description="Nenhum registro encontrado com esses parâmetros." style={{ padding: "20px", position: "absolute", top: "20px", left: "50%", transform: "translateX(-50%)" }}>
							<Button type="primary" onClick={() => window.close()}>Voltar</Button>
						</Empty>
					)
				)}
				<style dangerouslySetInnerHTML={{ __html: style }} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
