import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Affix, Empty, Pagination, Spin } from "antd";

import TableRow from "./row";

class Table extends Component {
	constructor() {
		super();

		this.state = {
			// Sticky header
			initialPosition: 0,
			difference: 0,
			screenWidth: window.innerWidth,
		}
	}

	static propTypes = {
		onPaginationChange: PropTypes.func,
		isLoading         : PropTypes.bool,
		data              : PropTypes.array,
		pagination        : PropTypes.object,
		columns           : PropTypes.arrayOf(
			PropTypes.shape({
				title    : PropTypes.string,
				className: PropTypes.string,
				width    : PropTypes.any,
				visible  : PropTypes.bool,
				render   : PropTypes.func.isRequired,
			}),
		),
		columnsTotal     : PropTypes.arrayOf(
			PropTypes.shape({
				title    : PropTypes.string,
				className: PropTypes.string,
				width    : PropTypes.any,
				visible  : PropTypes.bool,
				render   : PropTypes.func.isRequired,
			}),
		),
		emptyProps        : PropTypes.object,
		updateFuncionarioRow: PropTypes.func,
	};

	static defaultProps = {
		onPaginationChange: () => null,
		isLoading         : false,
		data              : [],
		pagination        : {},
		columns           : [],
		columnsTotal      : [],
		emptyProps        : {},
	};

	updateScreenWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

	componentDidMount() {
    // Sticky header
    this.setHeaderSticky();
    window.addEventListener('scroll', this.handleScroll, true);
		window.addEventListener('resize', this.updateScreenWidth);
	}

	componentDidUpdate() {
		// Sticky header
		this.setHeaderSticky();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll, true);
		window.removeEventListener('resize', this.updateScreenWidth);
	}

	componentWillUnmount() {
		// window.removeEventListener('scroll', this.handleScroll, true);
	}

	setHeaderSticky = () => {
		const header = this.header;
		if (header) {
			header.style.position = 'sticky';
			header.style.top = '0';
			header.style.zIndex = '1000';
		}
	};

	_renderEmpty = () => {
		return (
			<Empty {...this.props.emptyProps} />
		)
	};

	_renderList = () => {
		const { data, maxJoinColumns, columnsTotal } = this.props;

		if( !data.length )
		{
			return this._renderEmpty();
		}

		const columns = this.props.columns.filter(column => !column.hasOwnProperty("visible") || column.visible);

		const shouldUseCustomHeight = data.length >= 8;
		const listClassNames = `list-items-daily-point list-type ${shouldUseCustomHeight ? 'customHeight' : ''}`;

		return (
			<div>
				<div className={listClassNames}>
					<div ref={(el) => this.header = el} className="list-items-header">
						<div className="row">
							{columns.map((column, index) => {
								const isLastColumn = index === columns.length - 1;
								const key = column.key || column.title;
								let columnProps = {
									key: index,
									className: `col ${column.className || ''} ${isLastColumn ? 'last-column-timeCard' : ''}`,
									style: {},
								};
		
								if (column.width) {
									columnProps.style.width = column.width;
									columnProps.style.minWidth = column.width;
									columnProps.style.maxWidth = column.width;
								}
		
								return (
									<div {...columnProps} key={key}>
										<div>
											{column.titleRender ?? column.title}
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<div className="list-items-body">
						{data.map((item, index) => (
							<TableRow
								key={item.id}
								index={index}
								columns={columns}
								updateRow={this.props.updateRow}
								reloadRow={this.props.reloadRow}
								item={item}
								dataDia={this.props.dataDia}
								fetchGetAll={this.props.fetchGetAll}
								newJoinColumn={this.props.newJoinColumn}
								removeBatida={this.props.removeBatida}
								journeyOpen={this.props.journeyOpen}
								updateFuncionarioRow={this.props.updateFuncionarioRow}
							/>
						))}
						{/* Total */}
						<div className="list-items-item">
							<div className="row" style={{ fontWeight: 'bold' }}>
								{columnsTotal.map((column, col_index) => {
									const isLastColumn = col_index === columnsTotal.length - 1;
									let columnProps = {
										key: col_index,
										className: `col ${column.className || ""} ${isLastColumn ? 'last-column-timeCard' : ''}`,
										style: {},
									};

									if (column.title === 'Total') {
										const emptyJoinColumnsWidth = maxJoinColumns * 100 * 2;
										columnProps.style.minWidth = `calc(455px + (${emptyJoinColumnsWidth}px)`;
										columnProps.style.maxWidth = `calc(455px + (${emptyJoinColumnsWidth}px)`;
									} else {
										columnProps.style.minWidth = column.width;
										columnProps.style.maxWidth = column.width;
									}

									return (
										<div data-title={column.title} {...columnProps}>
											<Fragment>{column.render()}</Fragment>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
				<Pagination
					{...this.props.pagination}
					showSizeChanger
					onChange={this.props.onPaginationChange}
					showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} itens`}
				/>
			</div>
		)
	};

	handleScroll = () => {
		const tableContainer = document.querySelector(".page-listing");
		const alertMessage = document.querySelector(".ant-alert");
		
		const header = this.header;
		if (header && tableContainer) {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			const isHeaderVisible = scrollTop >= tableContainer.offsetTop;
			
			const alertHeight = alertMessage && alertMessage.offsetHeight;
			const alertVisible = alertMessage && alertMessage.style.display !== 'none';
	
			if (isHeaderVisible) {
				const offset = alertVisible ? alertHeight : 0;
				header.style.transform = `translateY(${scrollTop - tableContainer.offsetTop - 25 + offset}px)`;
			} else {
				header.style.transform = 'none';
			}
		}
	}	

	render() {
		return (
			<div className="page-listing page-listing-default">
				<Spin spinning={this.props.isLoading} indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}>
					{this._renderList()}
				</Spin>
			</div>
		)
	}
}

export default Table;
